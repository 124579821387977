/*-- Main Colors --*/
/*Green - #4CAF50*/
/*Orange darken-2 - #f57c00*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

/*-- Main Colors END--*/
body{
  background: #f5f5f5;
  font-size: 13px;
  overflow-anchor: none;
  font-family: 'Poppins', sans-serif!important;
}
  
body {
  font-family: ;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

body::-webkit-scrollbar {
   width: 15px;
}
body::-webkit-scrollbar-track {
  background:#FCFCFC;
}
body::-webkit-scrollbar-thumb {
  background: #D8D8D8;
  outline: 1px solid slategrey;
}
.navbar{
  padding: 0px; 
}
.navbar-light .navbar-nav a.nav-link{
  padding: 0.9rem 1rem;
  color: white;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
}
/*.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: white;
  transition: all 0.3s ease;
  background: #FFBE00;
}*/
.navbar-light .navbar-nav .show > .nav-link, .navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .nav-link.active{
  color: white;
  background: #FFBE00;
  transition: all 0.3s ease;
}
.navbar-nav .nav-item .dropdown-item{
  font-size: 0.9rem;
  padding: 0.45rem 1.5rem;
  color: #636363;
}
.navbar-nav .nav-item .dropdown-item.active,.navbar-nav .nav-item .dropdown-item:active {
  background-color: #FFBE00;
  color: #FFF!important;
}
/* -- Nav Customization--*/

/* -- Pages Css-- */

.gtMobVerify{
  margin-top: 30px;
  background: white;
  padding: 3rem;
}
.gtMobVerify h4{
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #525252;
}
.gtMobVerify h3{
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: 0px;
  color: #FFBE00;
}
.gtMobVerify p {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #525252;
  font-weight: 500;
}
.gtMobVerify h5 {
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #525252;
  font-weight: 500;
  font-size: 0.9rem;
  margin-top: 1.8rem;
}
.gtRefBox{
  margin-bottom: 1rem;
  box-shadow: 0px 2px 4px #00000026;
  /*border: none;*/
}
.gtRefBox .gtRefBoxB{
  padding: 0.7rem;
}
.gtRefBox .gtRefBoxB .form-control {
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(2.5rem + 2px);
}
.gtRefBox .gtRefBoxB .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtRefBox .gtRefBoxH h5{
  margin-bottom: 0px;
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #5a5a5a;
}
.gtRefBox .gtRefBoxH a{
  color: #b90707;
  font-size: 0.8rem;
  text-decoration: none;
}
.gtRefBox .gtRefBoxH{
  padding: 1rem 0.7rem;
  border-bottom: 1px solid #eaeaea;
}
.gtSetModal .btnEditSet{
  padding: 10px 30px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.gtSetModal .btnEditSet:hover,.gtSetModal .btnEditSet:focus{
  transition: all 0.3s ease;
  background: #FFBE00;
}
.gtSetEditPass{
  padding: 10px 30px;
  font-size: 1rem;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.gtSetEditPass:hover,.gtSetEditPass:focus{
  transition: all 0.3s ease;
  background: #FFBE00;
}
.gtSetModal .modal-header .close {
  position: absolute;
  right: 0;
}
.gtSetModal .modal-header{
  border-bottom: 0px;
}
.gtSetModal .modal-header h5{
  font-family: 'Poppins', sans-serif;
}
.gtBtnEditSet{
  background: #FFBE00;
  color: white;
  font-size: 0.8rem;
  transition: all 0.2s ease;
}
.gtBtnEditSet:hover,.gtBtnEditSet:focus{
  background: #FFBE00;
  color: white;
}
.gtSetStat h5{
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  color: #5f5f5f;
}
.gtSetStat h5.gtSetStatDet{
  color:#FFBE00;
}
.gtMainDetHeader a{
  text-decoration: none;
  color:#FFBE00;
}
.gtMainDetHeader h5 b{
  color:#FFBE00;
}
.gtMbadge{
  background: white;
  color: #FFBE00;
  padding: 4px 8px 3px 8px;
  margin-left: 5px;
}
.gtAftHeader img{
  max-height: 65px;
  width: 100%;
}
.gtAftHeader .gtHSetBtn{
  background: #FFBE00;
  color: white;
  font-size: 0.8rem;
  width: 100%;
  display: block;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 12px;
}
.gtAftHeader .dropdown-toggle{
  text-decoration: none;
}
.gtAftHeader .dropdown-toggle::after{
  border:none;
}
.gtAftHeader .gtMainDetHeader:hover,.gtAftHeader .gtMainDetHeader:focus{
  background: none;
}
.gtAftHeader .dropdown-item h5{
  font-size: 0.8rem;
  font-weight: 400;
}
.gtAftHeader .dropdown-item{
  padding: 0.4rem 1rem;
  font-size: 0.9rem;
}
.gtAftHeader .dropdown-item h4{
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0px;
}
.gtAftHeader .dropdown-item div{
  font-size: 13px;
  margin-bottom: 10px;
}
.btnBacktoSer{
  background: #FFBE00;
  color: white;
  font-size: 0.8rem;
}
.btnBacktoSer:hover,.btnBacktoSer:focus{
  background: #FFBE00;
  color: white;
}
.gtSearchResult .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFBE00;
}
.gtProBoxT{
  box-shadow: 0px 2px 4px #00000026;
  /*border: none;*/
}
.gtProBoxT .gtProBoxTBody a{
  padding: 15.5px 20px;
  font-size: 14px;
  background: #f5f5f5;
  color: #2f2f2f;
  font-weight: 400;
  text-decoration: none;
}
.gtProBoxT .gtProBoxTBody a:hover, .gtProBoxT .gtProBoxTBody a:focus {
  background-color: #FFBE00;
  color: white;
  transition: all 0.2s ease;
}
.gtMsgRes .gtExpressDet{
  font-size: 0.9rem;
  color: #FFBE00;
  font-weight: 500;
}
.gtMsgRes .gtExpressStat .gtBadge{
  background: #FFBE00;
  padding: 5px 10px 5px 10px;
  color: white;
  border-radius: 5px;
}
.gtPagination .page-link {
  color: #FFBE00;
  border: 1px solid #dedede;
}
.gtMsgRes img{
  max-height: 112.5px;
  width: 100%;
}
.gtMsgRes{
  box-shadow: 0px 2px 4px #00000026;
  /*border: none;*/
}
a.gtMsgReadFull{
  text-decoration: none !important;
  color: #FFBE00;
  font-weight: 500;
}
.gtMsgRes .gtMsgAct a{
  padding: 18px 30px;
  font-size: 13.5px;
  background: #f5f5f5;
  color: #2f2f2f;
  font-weight: 400;
  text-decoration: none;
}
.gtMsgRes .gtMsgAct a:hover,.gtMsgRes .gtMsgAct a:focus{
  background-color: #FFBE00;
  color: white;
  transition: all 0.2s ease;
}
.gtMsgRes .gtMsgResDet h4{
  font-size: 1.5rem;
  color: #FFBE00;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0px;
}
.gtMsgRes .gtMsgResDet p{
  font-size: 0.9rem;
  color: #434343;
}
.gtMsgFull h4{
  font-family: 'Poppins', sans-serif;
  color: #434343;
  font-size: 1.3rem;
}
.gtMsgFull p{
  font-family: 'Poppins', sans-serif !important;
  color: #434343;
  font-size: 0.9rem;
  padding: 1rem;
}
.gtNewMsg a{
  background-color: #FFBE00;
  color: white;
  padding: 1rem;
  text-decoration: none;
  display: block;
  font-size: 1rem;
  border-radius: 3px;
}
.gtMsgNav{
  border-radius: 3px;
  box-shadow: 0px 2px 4px #00000026;
  /*border: none;*/
}
.gtMsgNav a{
  display: block;
  padding: 1rem;
  background: #FFFFFF;
  text-decoration: none;
  font-size: 0.9rem;
  color: #484848;
  font-weight: 500;
}
.gtMsgNav a:hover,.gtMsgNav a:focus{
  background: #FFBE00;
  color: #fff;
  transition: all 0.2s ease;
}
.gtMainResult .gtMainResAct button{
  padding: 16px 20px;
  font-size: 13.5px;
  background: #f5f5f5;
  color: #2f2f2f;
  font-weight: 400;
}
.gtMainResult .gtMainResAct button:hover,.gtMainResult .gtMainResAct button:focus{
  background-color: #FFBE00;
  color: white;
  transition: all 0.2s ease;
}
.gtMainResult .gtResTag{
  font-size: 0.95rem;
  color: #616161;
}
.gtMainResult .gtResDet{
  font-size: 0.95rem;
  font-weight: 600;
  color: #616161;
  font-family: 'Poppins', sans-serif;
}
.gtMainResult{
  box-shadow: 0px 2px 4px #00000026;
  /*border: none;*/
}
.gtMainResult button{
  text-decoration: none;
}
.gtMainResult h3{
  font-size: 1.5rem;
  color: #FFBE00;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0px;
}
.gtMainResult h5{
  font-size: 0.9rem;
  color: #434343;
}
.gtEditForm .modal-body textarea.form-control {
  height: auto;
}
.gtEditForm .modal-body .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFBE00;
}
.gtEditForm .modal-header .close {
  position: absolute;
  right: 0;
}
.gtEditForm .modal-header{
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  border-bottom: 0px;
}
.gtEditForm .modal-header .modal-title{
  color: #545454;
}
.gtEditForm .modal-body label{
  color: #545454;
}
.gtEditForm .modal-body .custom-control label{
  padding-top: 2px;
}
.gtEditForm .modal-body .form-control {
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(2.5rem + 0px);
}
.gtEditForm .modal-body .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtEditForm .modal-body .custom-radio .custom-control-input:checked ~ .custom-control-label::before{
   background-color: #FFBE00;
}
.gtProBox{
  box-shadow: 0px 2px 4px #00000026;
  /*border: none;*/
}
.gtProBox .gtProBoxHead .btn-green {
  background-color:#FFBE00;
  color: white;
  padding: 0.4rem 1.2rem;
}
.gtProBox .gtProBoxHead .btn-green:hover,.gtProBox .gtProBoxHead .btn-green:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
  color: white;
}
.gtProBox .gtProBoxBody .gtProTxt{
  font-size: 0.8rem;
  color: #616161;
}
.gtProBox  .gtProBoxBody .gtProTxtDet{
  font-size: 0.8rem;
  font-weight: 600;
  color: #FFBE00;
  font-family: 'Poppins', sans-serif;
}
.gtProBox .gtProBoxHead {
  padding: 0.6rem;
  border-bottom: 1px solid #e4e4e4;
}
.gtProBox  .gtProBoxBody{
  padding: 1rem;
}
.gtProBox .gtProBoxHead h4{
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  font-size: 1.2rem;
  margin-top: 6px;
}
.gtProBoxT .gtProBoxTBody .gtProTDet{
  font-weight: 500;
  color: #FFBE00;
  font-family: 'Poppins', sans-serif;
}
.gtProBoxT .gtProBoxTBody .gtProTTag{
  color: #616161;
}
.gtProBoxT .gtProBoxTBody{
  font-size: 14px;
}
.gtBtnRigRes{
  font-size: 13px;
  background: #FFBE00;
  color: white;
  border-radius:0px 20px 20px 0px;
}
.gtBtnLeftRes{
  font-size: 13px;
  background: #FFBE00;
  color: white;
  border-radius:20px 0px 0px 20px;
}
.gtRightProFoot{
  text-align: center;
  padding-bottom: 1rem;
}
.gtRightProFoot .btn-group > .btn:not(:last-child):not(.dropdown-toggle),.gtRightProFoot .btn-group > .btn-group:not(:last-child) > .btn {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.gtRightProFoot .btn-group > .btn:not(:first-child),.gtRightProFoot .btn-group > .btn-group:not(:first-child) > .btn {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.gtRightPro{
  box-shadow: 0px 2px 4px #00000026;
  /*border: none;*/
}
.gtSideResult img{
  max-height: 75.41px;
  width: 100%;
}
.gtSideResult a{
  text-decoration: none;
}
.gtSideResult h5{
  font-size: 1rem;
  color: #1E1E1E;
}
.gtSideResult p{
  font-size: 12px;
  color: #1E1E1E;
}
.gtRightPro .gtRightProHead{
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #dfdfdf;
  font-size: 1.1rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.gtRightPro .gtRightProBody{
  padding: 1rem 1rem 0rem 0.5rem;
}
.gtbtnExpSmall{
  font-size: 13px;
  background: #FFBE00;
  color: white;
  border-radius: 24px;
}
.gtbtnExpSmall:hover,.gtbtnExpSmall:focus{
  transition: all 0.3s ease;
  background: #FFBE00;
   color: white;
}
.gtBtnExpSM{
  font-size: 12px;
  background: #FFBE00;
  color: white;
  border-radius: 24px;
}
.gtBtnExpSM:hover,.gtBtnExpSM:focus{
  transition: all 0.3s ease;
  background: #FFBE00;
   color: white;
}
.gtSMresult img{

  width: 100%;
}
.gtSMresult .gtSMresultName{
  font-size: 1rem;
  display: block;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #FFBE00;
}
.gtSMresult .gtSMresultDet{
  text-align: center;
  text-decoration: none;
  color: #1E1E1E;
}
.gtSMresult a{
  text-decoration: none;
}
.gtHomeBox{
  box-shadow: 0px 2px 4px #00000026;
  /*border: none;*/
}
.gtHomeBox .gtHomeBoxFooter{
  padding: 1rem;
  background: #ffffff;
  color: #797979;
  text-align: center;
  font-size: 0.9rem;
  text-decoration: none;
  border-top: 1px solid #dfdfdf;
  font-weight: 500;
}
.gtHomeBox .gtHomeBoxFooter:hover{
  transition: all 0.3s ease;
  color: #353535;
}
.gtHomeBox .gtHomeBoxHead{
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #dfdfdf;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.gtHomeBoxBody{
  padding: 1rem;
}
.gtHTCard{
  box-shadow: 0px 2px 4px #00000026;
  
}
.gtHTCard .bg-warning{
  background-color: #FFBE00 !important;
}
.gtHTCard  .gtComDetBtn{
  background: #FFBE00;
  color: #ffffff;
  font-size: 0.75rem;
}
.gtHTCard  .gtComDetBtn:hover,.gtHTCard  .gtComDetBtn:focus{
  transition: all 0.3s ease;
  background: #FFBE00;
}
.gtHTCard .gtComDetIcon i{
  font-size: 2rem;
  color: #FFBE00;
}
.gtHTCard .gtComDet{
  font-size: 1rem;
  margin-bottom: 10px;
  margin-top: 5px;
}
.gtHTCard .carousel-control-prev,.gtHTCard .carousel-control-next {
  text-align: left;
  opacity: 1;
  color: #FFBE00;
  font-size: 1rem;
  width: 10%;
  background: #00000008;
}
.gtHTCard .carousel{
  background: white;
  padding-top: 15px;
  padding-bottom: 15px;
}
.gtHTCard h4{
  font-size: 1.2rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.gtHTCard h5{
  font-size: 1rem;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.gtLeftBox{
  box-shadow: 0px 2px 4px #00000026;

}
.gtHRigDetCard{
  box-shadow: 0px 2px 4px #00000026;

}
.gtHRigDetCard .card-img-top img{
  width: 100%;
  max-height: 255px;
}
.gtBtnLSear{
  background: #FFBE00;
  color: white;
  font-size: 1rem;
}
.gtBtnLSear:hover,.gtBtnLSear:focus{
  background: #FFBE00;
  color: white;
  font-size: 1rem;
}
.gtLeftBox .gtLeftBoxBody .form-control{
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(2.5rem + 2px);
}
.gtLeftBox .gtLeftBoxBody .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtLeftBox .gtLeftBoxBody {
  padding: 10px 0px;
}
.gtLeftBox .gtLeftBoxBody a{
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #3A3A3A;
  font-size: 0.9rem;
  width: 100%;
}
.gtLeftBox .gtLeftBoxBody .badge{
  padding: 5px 10px;
  background: #FFBE00;
  color: white;
}
.gtLeftBox .gtLeftBoxBody a:hover,.gtLeftBox .gtLeftBoxBody a:focus{
  background: #F3F3F3;
  color: #FFBE00;
  transition: all 0.3s ease;
}
.gtLeftBox .gtLeftBoxHead{
  background: white;
}
.gtLeftBox .gtLeftBoxHead h4{
  font-size: 1.2rem;
  margin-bottom: 0px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
a.gtBtnHup{
  padding: 0.8rem 1rem;
  color: #ffffff;
  background: #FFBE00;
  text-decoration: none;
  font-size: 0.9rem;
}
a.gtBtnHup:hover,a.gtBtnHup:focus{
  color: white;
  transition: all 0.3s ease;
  background: #FFBE00;
}
.gtHRigDetCard .card-img-top h5{
  font-size: 0.8rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
  padding: 10px;
  background: #FFBE00;
  color:white;
}
.gtHRigDetCard .card-body{
  padding: 10px 15px 10px 15px;
}
.gtHRigDetCard .card-body h4{
  font-size: 1.3rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
}
.gtCardLink a{
  padding: 10px 15px;
  display: flex;
  background: #ffffff;
  text-decoration: none;
  color: #FFBE00;
  font-size: 0.8rem;
}
.gtCardLink{
  border-top: 1px solid #e6e6e6;
}
.gtHRigDetCard .card-body p{
  margin-bottom: 0px;
}
.gtHRigDetCard img{
  padding: 5px;
}
.gtMainResult {
  background: #FFFFFF;
  
}
.bor-card {
  border: 1px solid #eee;
  margin-top: 10px;
  margin-bottom: 10px;
}


.gtMainResult img{
  height: 217px;
  width: 100%;
}
.btnBackToLog{
  padding: 8px 28px;
  font-size: 14px;
  font-weight: 500;
  color: #FFBE00;
  background: #ececec;
  border-radius: 3px;
}
.btnBackToLog:hover,.btnBackToLog:focus{
  transition: all 0.3s ease;
  color:white;
  background: #FFBE00;
}
.gtLogRegNow{
  text-align: center;
  height: 100%;
  background: #B9A5310d;
}
.gtLogRegNow h5{
  font-size: 1rem;
}
.gtLogRegNow .btnLogReg{
  padding: 10px 34px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.gtSucBody .page-link {
   color: #FFBE00;
   border: 1px solid #dedede;
}
.btnSucPost{
  padding: 12px 36px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.btnSucPost:hover, .btnSucPost:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
}
.gtSucPill .nav-link{
  color: #FFBE00;
  background-color: #ffffff;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 0rem;
  box-shadow: 0px 2px 2px 0px #00000038;
}
.gtSucPill .nav-link.active,.gtSerPill .show .nav-link {
  color: #fff;
  background-color: #FFBE00;
}
.gtSucBody{
  background: white;
  padding: 2rem;
}
.gtSucBody h3{
  margin-top: 0px;
  margin-bottom: 5px;
  font-family: 'Poppins', sans-serif;
  color: #525252;
}
.gtSucBody p{
  font-family: 'Poppins', sans-serif;
  color: #525252;
  font-weight: 500;
}
.gtSucBody .form-control{
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(2.5rem + 2px);
}
.gtSucBody textarea.form-control {
  height: auto;
}
.gtSucBody .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtSucBody .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFBE00;
}
.gtSucBody .custom-radio .custom-control-input:checked ~ .custom-control-label::before{
   background-color: #FFBE00;
}
.gtSucBody .gtSucDisplay .card{
  border-radius: 0.15rem;
  margin-bottom: 1.5rem;
  box-shadow: 0px 2px 4px 0px #0000002b;
  width: 100%;
  /*! min-height: 390px; */
}
.gtSucBody .gtSucDisplay .card .card-img-top img{
  width: auto;
  min-height: 215px;
}
.gtSucBody .gtSucDisplay .card .card-body{
  padding: 1rem;
  height: auto;
}
.gtSucBody .gtSucDisplay .card .card-body .card-title{
  font-size: 1rem;
  text-align: center;
  color: #FFBE00;
  font-family: 'Poppins', sans-serif;
}
.gtSucBody .gtSucDisplay .card .card-body .card-text{
  text-align: center;
}
.gtSucBody .btnSucReMo{
  background: transparent;
  color: #FFBE00;
  font-size: 15px;
  padding: 10px 10px;
  border-radius: 0.15rem;
  border: 3px solid #FFBE00;
}
.gtSucBody .btnSucReMo:hover,.gtSucBody .btnSucReMo:focus{
  transition: all 0.3s ease;
  background: #FFBE00;
  color: white;
}
.gtSerPill .nav-link{
  color: #FFBE00;
  background-color: #ffffff;
  padding: 1rem;
  font-size: 1rem;
  border-radius: 0rem;
  box-shadow: 0px 2px 2px 0px #00000038;
}
.gtSerPill .nav-link.active,.gtSerPill .show .nav-link {
  color: #fff;
  background-color: #FFBE00;
}
.gtSerBody{
  background: white;
  padding: 2rem;
}
.gtSerBody h3{
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #525252;
}
.gtSerBody p{
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #525252;
  font-weight: 500;
}
.gtSerBody .form-control{
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(2.5rem + 2px);
}
.gtSerBody .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtSerBody .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFBE00;
}
.gtSerBody .custom-radio .custom-control-input:checked ~ .custom-control-label::before{
   background-color: #FFBE00;
}
.gtSignModal .modal-header .close{
   padding: 18px 0px;
}
.gtSignModal .modal-header h5{
  font-size: 26px;
  font-weight: 500;
  color: #525252;
}
.gtSignModal .modal-header p{
  margin-bottom: 0px;
  font-size: 14px;
  color: #525252;
}
.gtSignModal .modal-header{
  border-bottom: 0px;
}
.gtSignModal  .modal-body{
  padding: 1rem 2rem;
}
.gtSignModal  .modal-body label{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #525252;
}
.gtSignModal  .modal-body .form-control {
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(3rem + 2px);
}
.gtSignModal  .modal-body .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtSignModal  .modal-body .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFBE00;
}
.gtLogModal .modal-header .close {
  padding: 18px 0px;
}
.gtLogModal .modal-header h5{
  font-size: 26px;
  font-weight: 500;
  color: #525252;
}
.gtLogModal .modal-header p{
  margin-bottom: 0px;
  font-size: 14px;
  color: #525252;
}
.gtLogModal .modal-header{
  border-bottom: 0px;
}
.gtLogModal .modal-body{
  padding: 1rem 2rem;
}
.gtLogModal .modal-body label{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  color: #525252;
}
.gtLogModal .modal-body .form-control {
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(3rem + 2px);
}
.gtLogModal .modal-body .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtLogModal .modal-body .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFBE00;
}
.gtLogModal .modal-body .gtForgTxt{
  display: block;
  padding-top: 3px;
  color: #525252;
  text-decoration: none;
}
.gtLogModal .modal-body .gtForgTxt:hover{
  color: #000000;
}
.gtBorderOr{
  display: block;
  margin-top: 10px;
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
}
.gtBorderOr span{
  display: inline-block;
  position: relative;
  color: #4b4f56;
  text-align: center;
  white-space: nowrap;
}
.gtBorderOr span:before,.gtBorderOr span:after {
  background: #e4e4e4;
  content: "";
  height: 1px;
  position: absolute;
  top: 50%;
  width: 9999px;
}
.gtBorderOr span:before {
  margin-right: 15px;
  right: 100%;
}
.gtBorderOr span:after {
  left: 100%;
  margin-left: 15px;
}
.btnSignModal{
  padding: 12px 36px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.btnSignModal:hover,.btnSignModal:focus{
  transition: all 0.3s ease;
  background: #FFBE00;
}
.btnLogModal{
  padding: 12px 36px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.btnLogModal:hover,.btnLogModal:focus{
  transition: all 0.3s ease;
  background: #FFBE00;
}
.gtSignModalTxt h5{
  font-size: 14px;
  margin-bottom: 0px;
}
.btnSignUpCall{
  color: #FFBE00;
  background: transparent;
  border: 3px solid #FFBE00;
  padding: 12px 36px;
  font-size: 16px;
}
.btnSignUpCall:hover,.btnSignUpCall:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
  color:white;
}
.gtSignModal .modal-body .gtSignTxt{
  text-align: center;
  font-size: 15px;
}
.gtSignModal .modal-body .gtSignTxt a{
  color:#FFBE00;
}
.btnRegFsubmit{
  padding: 12px 36px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
  text-align: center !important;
}
.btnRegFsubmit:hover, .btnRegFsubmit:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
}
.btnRegFclear{
  padding: 12px 36px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background: #69b700;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
  width: 150px !important;
  margin: 0 auto 0;
}
.btnRegFclear:hover, .btnRegFclear:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
}
.gtMemPlan{
  background: white;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
}
.gtMemPlan .gtMemPName{
  background: #FFBE00;
  padding-top: 18px;
  color: #ffffff;
  padding-bottom: 18px;
}
.gtMemPlan .gtMemPName h4{
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
}
.gtMemPlan .gtMemPName h5{
  font-weight: 500;
  font-size: 1.4rem;
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
}
.gtMemPlan .gtMemPDetail{
  padding-top: 10px;
  padding-bottom: 10px;
}
.gtMemPlan .gtMemPDetail p{
  margin-bottom: 5px;
}
.gtMemPlan .gtMemPDetail h4{
  margin-bottom: 0px;
}
.btnMemPlanSel{
  display: block;
  padding: .65rem;
  background: #FFBE00;
  color: white;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.btnMemPlanSel:hover, .btnMemPlanSel:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
  color: white;
}
.gtMemPSelect{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.gtPayCart{
  background: white;
  padding: 1rem;
}
.gtPayCartAmount p{
  margin-bottom: 0px;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
}
.gtPayCartAmount h4{
  font-size: 2rem;
  font-weight: 600;
  color: #FFBE00;
}
.gtPayCart h4{
  font-size: 1rem;
  margin-bottom: 0px;
  color: #FFBE00;
  font-family: 'Poppins', sans-serif;
}
.gtPayCart p{
  margin-bottom: 5px;
}
.gtPayOpt{
  background: white;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 5px;
}
.gtPayOpt h4{
  font-size: 1rem;
  padding-top: 1.55rem;
  margin-bottom: 0px;
  padding-bottom: 1.55rem;
}
.btnPayGate{
  padding: 6px 35px;
  font-size: 14px;
  font-weight: 400;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.btnPayGate:hover, .btnPayGate:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
  color:white;
}
.razorpay-payment-button{
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  line-height: 1.5;
  display: block;
  width: 100%;
  padding: 6px 35px;
  font-size: 14px;
  font-weight: 400;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.razorpay-payment-button:hover,.razorpay-payment-button:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
  color:white;
}
.gtBankDetModal .modal-header h5 {
  font-size: 26px;
  font-weight: 500;
  color: #FFBE00;
}
.gtBankDetModal .modal-header{
  border-bottom: 0px;
}
.gtBankDetModal .gtBaDetTitle{
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
}
.gtBankDetModal .gtBaDetDesc{
  font-size: 14px;
  font-weight: 500;
  font-family: 'Poppins', sans-serif;
  color: #FFBE00;
}
.gtConDet{
  background: white;
}
.gtConDet .gtConDetHead{
  padding: 1rem;
  background: #FFBE00;
  color:white;
  font-size: 1.1rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
.gtConDet .gtConDetBody{
  padding: 1rem;
}
.gtConDetBody .gtConTitle{
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 0px;
}
.gtConDetBody .gtConSubTitle {
  font-size: 1.1rem;
  margin-bottom: 1rem;
  margin-top: 0px;
  color: #FFBE00;
  word-break: break-word;
}
.gtConDet .gtConDetFooter{
  padding: 1rem;
  background: #E5E5E5;
  margin-top: 0.3rem;
}
.gtConDet .gtConDetFooter a{
  padding-right:3px;
  padding-left: 3px;
  text-decoration: none;
}
.gtConDet .gtConDetFooter a i {
  font-size: 2rem;
  color: #FFBE00;
}
.gtConForm .form-control{
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(2.5rem + 2px);
}
.gtConForm textarea.form-control {
  height: auto;
}
.gtConForm .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtConForm{
  background: white;
  padding: 2rem;
}
.gtRegMain{
  background: white;
  padding: 2rem;
}
.gtRegMain .form-control{
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(2.5rem + 0px);
}
.gtRegMain textarea.form-control {
  height: auto;
}
.gtRegMain .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtRegMain .gtRegLink{
  color: #414141;
  font-weight: 800;
}
.gtRegMain  .custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFBE00;
}
.gtRegMain .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFBE00;
}
.btnProSkip{
  padding: 5px 20px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.btnProSkip:hover, .btnProSkip:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
  color:white;
}
.btnSelect{
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.btnSelect:hover, .btnSelect:focus {
  color: white;
  transition: all 0.3s ease;
  background: #FFBE00;
}
.gtLogMain{
  background: white;
  padding: 3rem;
}
.gtLogMain .form-control{
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(3rem + 2px);
}
.gtLogMain .gtForgTxt{
  display: block;
  padding-top: 3px;
  color: #525252;
  text-decoration: none;
}
.gtLogMain .gtForgTxt:hover{
  color: #000000;
}
.gtLogMain .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtLogMain .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #FFBE00;
}
.btnLogMain {
  padding: 12px 36px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.btnLogMain:hover, .btnLogMain:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
}

#photo1_prev{
  height: 225px;
  width: 225px;
}
.gtPhotoUpload{
  box-shadow: 0px 2px 4px #00000026;
  border: none;
}
.gtPhotoUpload img{
  padding: 5px;
}
.gtPhotoUpload .card-body{
  padding: 0rem;
}
.gtPhotoUpload a{
  display: block;
  text-decoration: none;
  color: #FFBE00;
  font-size: 0.9rem;
  padding: 1rem;
}
.gtPhotoUpload a:hover{
  transition: all 0.3s ease;
  background: #fd5709;
  color: white;
}
.gtPhotoUpload h4{
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
  padding: 10px;
  background: #59b104;
  color: white;
  text-align: center;
  border-radius: 3px 3px 0px 0px;
}
.gtUploadMPhotoes{
  box-shadow: 0px 2px 4px #00000026;
  /*border: none;*/
  margin-bottom: 1rem;
}
.gtUploadMPhotoes img{
  padding: 5px;
}
.gtUploadMPhotoes .card-body{
  padding: 0rem;
}
.gtUploadMPhotoes a{
  display: block;
  text-decoration: none;
  color: #FFBE00;
  font-size: 0.9rem;
  padding: 1rem;
  transition: all 0.3s ease;
}
.gtUploadMPhotoes a:hover{
  background: #59b104;
  color: white;
}
.gtUploadMPhotoes h4{
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 5px;
  padding: 10px;
  background: #fd5709;
  color: white;
  text-align: center;
  border-radius: 3px 3px 0px 0px;
}
.gtPhotoPrivacyLink a{
  color: #fd5709;
  font-size: 0.9rem;
  font-weight: 500;
}
.gtPayConfirm h1{
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-size: 50px;
  margin-top: 60px;
  color: #525252;
  text-align: center;
  margin-bottom: 0px;
}
.gtPayConfirm h3{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 30px;
  margin-top: 0px;
  color: #FFBE00;
  text-align: center;
}
.gtPayConfirm p{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-align: center;
}
.gtInterestMod h3{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-top: 40px;
}
.validetta-inline{
  margin-top: 10px;
}
.gtRegisterConf{
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}
.gtRegisterConf i{
  font-size: 78px;
  color: #FFBE00;
}
.gtRegisterConf h3{
  font-family: 'Poppins', sans-serif;
  text-align: center;
  margin-top: 30px;
  font-weight: 600;
  color: #525252;
  font-size: 30px;
  margin-bottom: 0px;
}
.gtRegisterConf .alert-heading{
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
}
.gtRegisterConf .alert p{
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
}
.gtRegisterConf  p{
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

.gtTopWhiteStrip{
  background: rgba(255, 255, 255, 0.8);
  padding-top: 20px;
  padding-bottom: 20px;
}
.gtTopWhiteStrip h4{
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-bottom: 0px;
  font-size: 24px;
  color: #FFBE00;
}
.gtTopWhiteStrip h5{
  font-size: 14px;
  letter-spacing: 0.3px;
  font-family: 'Poppins', sans-serif;
  color: #303030;
  margin-bottom: 15px;
  font-weight: 600;
}
.gtTopWhiteStrip .form-control {
  font-size: 0.9rem;
  border-radius: 0.15rem;
  height: calc(3rem + 2px);
}
.gtTopWhiteStrip .form-control:focus {
  border-color: #FFBE00;
  box-shadow: 0 0 0 0.2rem #BF010052;
}
.gtTopWhiteStrip .btn:hover, .gtTopWhiteStrip .btn:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
}
.gtTopWhiteStrip .btn {
  padding: 12px 36px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  background: #FFBE00;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
}
.gtDesc{
  text-align: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f9f9f9;
}
.gtDesc i{
  font-size: 40px;
  color: #FFBE00;
}
.gtDesc h4{
  font-family: 'Poppins', sans-serif;
  color: #303030;
  margin-top: 20px;
  font-weight: 600;
}
.gtDesc p{
  font-family: 'Poppins', sans-serif;
  color: #6c6c6c;
  font-size: 16px;
}
.gtFetPro{
  padding-top: 50px;
  padding-bottom: 50px;
  background: white;
}
.gtFetPro h4{
  font-family: 'Poppins', sans-serif;
  color: #FFBE00;
  font-weight: 600;
  font-size: 26px;
  margin-bottom: 0px;
}
.gtFetPro p{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
   color: #303030;
}
#gtFetBride .item{
  margin: 20px;
  border-radius: 5px;
  display: block;
  text-decoration: none;
}
#gtFetBride .item img{
  display: block !important;
  width: 100% !important;
  height: auto !important;
  border-radius: 5px;
}
#gtFetGroom .item{
  margin: 20px;
  border-radius: 5px;
  display: block;
  text-decoration: none;
}
#gtFetGroom .item img{
  display: block !important;
  width: 100% !important;
  height: auto !important;
  border-radius: 5px;
}
.gtFetCard{
  box-shadow: 0px 1px 8px 1px rgba(0, 0, 0, 0.16);
}
.gtFetCard .gtUserDet{
  padding:10px;
}
.gtFetCard .gtUserDet h3{
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 0px;
  font-weight: 700;
  color: #FFBE00;
}
.gtFetCard .gtUserDet h4{
  font-size: 14px;
  color: #8c8c8c;
  margin-bottom: 10px;
  margin-top: 0px;
}
.gtFetCard .gtUserDet p{
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 0px;
  color: #8c8c8c;
}
.gtVenHome{
  /*background: url(../img/vendor_home_bg.jpg) no-repeat;*/
  background-size: cover;
  padding-top: 70px;
  padding-bottom: 70px;
}
.gtVenHome h3{
  font-family: 'Poppins', sans-serif;
  color: #ffffff;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 0px;
  text-align: center;
}
.gtVenHome p{
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  color: #FFFFFF;
  text-align: center;
  margin-bottom: 20px;
}
#gtFetVendor .item{
  margin: 20px;
  border-radius: 5px;
  display: block;
  text-decoration: none;
  box-shadow: 0px 1px 7px 3px rgba(0, 0, 0, 0.24);
}
#gtFetVendor .item img{
  display: block !important;
  width: 100% !important;
  height: auto !important;
  border-radius: 5px;
}
.gtVenCard{
  background: white;
  border-radius: 5px;
}
.gtVenCardDet{
  padding: 15px;
}
.gtVenCardDet h1{
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  text-align: center;
  color: #FFBE00;
  margin-bottom: 15px;
}
.gtVenCardDet h3{
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: #737373;
  text-align: left;
  font-weight: 500;
}
.gtVenCardDet h4{
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  color: #737373;
  text-align:right;
}
.gtVenHome .btn{
  padding: 12px 36px;
  font-size: 16px;
  font-weight: 500;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 0px 2px 2px 0px #FFBE00;
  color: #FFBE00;
}
.gtVenHome .btn:hover,.gtVenHome .btn:focus{
  transition: all 0.3s ease;
  background: #F5F5F5;
}
.gtBtnFetHomeRight{
  font-size: 13px;
  background: #FFBE00;
  color: white;
  border-radius: 0px 20px 20px 0px;
}
.gtBtnFetHomeLeft{
  font-size: 13px;
  background: #FFBE00;
  color: white;
  border-radius: 20px 0px 0px 20px;
}
.gtAndroidDown{
  background: white;
  padding-top: 80px;
  padding-bottom: 80px;
}
.gtAndroidDown .gtAndroidDownDet{
  padding-left: 30px;
  padding-right: 30px;
}
.gtAndroidDown .gtAndroidDownDet h4{
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  line-height: 40px;
  color: #424242;
  margin-bottom: 20px;
}
.gtAndroidDown .gtAndroidDownDet h1{
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #FFBE00;
  margin-bottom: 20px;
}
.gtAndroidDown .gtAndroidDownDet img{
  max-height: 60px;
}
.gtWhyHome{
  padding-top: 40px;
  padding-bottom: 40px;
  background: #f9f9f9;
}
.gtWhyHome h1{
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: 600;
  color: #303030;
}
.gtWhyHome article{
  font-family: 'Poppins', sans-serif;
  font-size: 18px;
  font-weight: 400;
  color: #6f6f6f;
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 30px;
}
.gtWhyHome h4{
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: 600;
   color: #303030;
  margin-top: 20px;
  margin-bottom: 20px;
}
.gtWhyHome ul{
  margin: 0px;
  padding: 0px;
}
.gtWhyHome ul li{
  list-style-type: none;
  font-weight: 200;
  margin-bottom: 20px;
  font-size: 18px;
  color: #6f6f6f;
}
.gtWhyHome ul li i{
  padding-right: 10px;
}
.gtWhyHome img{
  max-height: 500px;
}
footer{
  background: #ffffff;
  /*border-top: 10px solid rgba(187, 1, 0, 0.36);*/
  margin-top: 70px;
}
footer .gtFootF{
  text-align: center;
  padding-top: 20px;
}
footer .gtFootF ul{
  margin: 0px;
  padding: 0px;
  list-style-type: none;
  display: inline-block;
  font-size: 14px;
}
footer .gtFootF ul li{
  display: inline-block;
  border-right: 1px solid #c5c5c5;
  list-style-type: none;
}
footer .gtFootF ul li:last-child{
  border-right: none;
}
footer .gtFootF ul li a{
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  color: #303030;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-decoration: none;
}
footer .gtFootF ul li a:hover,footer .gtFootF ul li a:focus{
  transition: all 0.3s ease;
  color: #000000;
}
footer .gtFootF {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 20px;
}
footer .gtFootTag{
  background: #FFBE00;
  padding: 7px 14px 7px 15px;
  color: white;
  border-radius: 30px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
}
footer h3{
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: #303030;
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
}
footer .gtFootS{
  padding-top: 30px;
  padding-bottom: 30px;
}
footer .gtFootS h4{
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}
footer .gtFootS ul{
  margin: 0px;
  padding: 0px;
}
footer .gtFootS ul li{
  list-style-type: none;
  font-family: 'Poppins', sans-serif;
}
footer .gtFootS ul li a{
  padding-top: 5px;
  padding-bottom: 5px;
  color: #FFBE00;
  font-weight: 500;
  font-size: 14px;
  display: block;
  text-decoration: none;
}
footer .gtFootS ul li a:hover,footer .gtFootS ul li a:focus{
   color: #303030;
  transition: all 0.3s ease;
}
footer .gtFootS .gtFootSocial ul li{
  display: inline-block;
}
footer .gtFootS .gtFootSocial ul li a{
  font-size: 30px;
  padding-left: 3px;
  padding-right: 3px;
  transition: all 0.3s ease;
}
footer .gtFootS img{
  max-width: 120px;
}
footer .gtFootT{
  padding-top: 15px;
  padding-bottom: 15px;
  background: #FFBE00;
  color: white;
  text-align: center;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
footer .gtFootT a{
  font-weight: 700;
  color: #e1ffc4;
  text-decoration: none;
}
.gtReminderSent h4{
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
  color: #303030;
  font-size: 18px;
}
.gtLoadingText{
  position:fixed;
  left:50%;
  top:70%;
  z-index:-1;
  opacity:0;
  background: rgba(0,0,0,0.20);
  height: 100%;
  width:100%
}
.gtContactCountCheck{
  padding: 15px 15px 40px 15px;
}
.gtContactCountCheck h4.modalTitle{
  font-size: 26px;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  color: #FFBE00;
  font-weight: 700;
}
.gtContactCountCheck h4{
  font-size: 20px;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;
  color: #303030;
  font-weight: 500;
}
.gtContactCountCheck h3{
  font-size: 18px;
  margin-top: 15px;
  font-family: 'Poppins', sans-serif;
  color: #303030;
  font-weight: 500;
}
.gtContactCountCheck a.btn{
  font-size: 16px;
  padding: 10px 10px 10px 10px;
  /*border: none;*/
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.4);
  border-radius: 3px;
}
.gtContactCountCheck a.btn-orange{
  background: #FFBE00;
  color: white;
}
.gtContactCountCheck a.btn-orange:hover,.gtContactCountCheck a.btn-orange:focus{
  transition: all 0.3s ease;
  background: #FFBE00;
  color: white;
}
.gtContactCountCheck a.btn-green{
  background: #FFBE00;
  color: white;
}
.gtContactCountCheck a.btn-green:hover,.gtContactCountCheck a.btn-green:focus{
  transition: all 0.3s ease;
  background: #FFBE00;
  color: white;
}
.gtContactDetModal h1{
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
}
.gtConDetTagModal{
  font-size: 15px;
  letter-spacing: 0.5px;
  color: #303030;
}
.gtConDetModal{
  font-size: 15px;
  letter-spacing: 0.5px;
  font-weight: 400;
  color: #FFBE00;
}
.gtModalUpCount h4{
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  line-height: 30px;
}
.gtSearchResult .custom-radio .custom-control-input:checked ~ .custom-control-label::before{
   background-color: #FFBE00;
}
.gtCMS .card-header{
  background: white;
}
.gtCMS .card-header h3{
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Poppins', sans-serif;
  color: #FFBE00;
  font-weight: 500;
  font-size: 1.7rem;
  padding: 5px;
}
.gtRegDivider{
  margin-top: 18px;
  /*margin-top: 40px;*/
  margin-bottom: 30px;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}
.gtRegDivider i{
  margin-right: 10px;
  color:#FFBE00;
}
/* -- Pace Loader css --*/
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
.pace-inactive {
  display: none;
}
.pace .pace-progress {
  background: #FFBE00;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 5px;
}

/*-- Reusable Classes --*/
.cursor-pointer{
  cursor: pointer;
}
.br3{
  border-radius: 3px;
}
.m0{
  margin: 0px !important;
}
.ml0{
  margin-left: 0px !important;
}
.mt0{
  margin-top: 0px !important;
}
.mb0{
  margin-bottom: 0px !important;
}
.mb5{
  margin-bottom: 5px !important;
}
.mb10{
  margin-bottom: 10px !important;
}
.mb15{
  margin-bottom: 15px !important;
}
.mb20{
  margin-bottom: 20px !important;
}
.mb40{
  margin-bottom: 40px !important;
}
.mt15{
  margin-top: 15px;
}
.mt20{
  margin-top: 20px;
}
.mt30{
  margin-top: 30px;
}
.mt40{
  margin-top: 40px;
}
.mt100{
  margin-top: 100px;
}
.p5{
  padding: 5px;
}
.pt3{
  padding-top: 3px;
}
.plrM15{
  margin-left: -0.75rem;
  margin-right:-0.75rem;
}
.pt40{
  padding-top: 40px;
}
.pt15{
  padding-top: 15px;
}
.pb15{
  padding-bottom: 15px;
}
.pb40{
  padding-bottom: 40px;
}
.maxH132{
  max-height: 132px;
}
.maxH75{
  max-height: 75px;
}
.bg-orange {
   background: #FFBE00;
}
.bg-green {
   background: #FFBE00;
}
.btn-green{
  background-color: #FFBE00;
  color: white;
  padding: 0.5rem .85rem;
  font-size: 14px;
  font-weight: 400;
}
.btn-green:hover,.btn-green:focus{
  background-color: #FFBE00;
  color: white;
  transition: all 0.3s ease;
}
.gtPageTitle{
  margin-top: 0px;
  margin-bottom: 5px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #525252;
}
.gtPageSubTitle{
  text-align: center;
  font-family: 'Poppins', sans-serif;
  color: #525252;
  font-weight: 500;
}
.gtBorderBtmGrey1{
  border-bottom: 1px solid #e6e6e6;
}
.gtBorderRight{
  border-right: none;
}
.font-1rem{
  font-size: 1rem;
}
.overflow-scroll-200{
  max-height: 200px;
  overflow-y: scroll;
}
/* -- Common Classes --*/
.loader{
  text-align: center;
  margin-top: 20%;
}

/* -- media query --*/
@media (max-width: 575.98px){
  .gtBLHeadBtn .btn-green {
    background-color: #FFBE00;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 13px;
    font-weight: 400;
  }
  .navbar-toggler {
    padding: 0.25rem 1rem;
    font-size: 1.25rem;
    line-height: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: white !important;
    border: 1px solid #fff;
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgb(255, 255, 255);
  }
  .navbar{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .gtTopBg {
    padding-top: 0px;
  }
  .gtDesc h4 {
    margin-top: 10px;
    font-weight: 500;
    font-size: 13px;
    text-align: left;
  }
  .gtDesc p {
    font-size: 12px;
    text-align: left;
  }
  .gtDesc i {
    font-size: 34px;
    margin-top: 20px;
  }
  .gtDesc {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #f9f9f9;
  }
  .gtWhyHome h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #303030;
  }
  .gtWhyHome {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #f9f9f9;
  }
  .gtLogMain{
    padding: 1rem;
  }
  .gtLogRegNow {
    background: transparent;
  }
  .gtXSP15{
    padding: 15px;
  }
  .gtProBox .gtProBoxHead h4 {
    font-size: 1rem;
    margin-top: 10px;
  }
  .gtMsgResDet{
    padding-left: 30px;
    padding-right: 30px;
  }
  .gtMsgResDate{
    text-align: left !important;
    padding-left: 30px;
    padding-right: 30px;
  }
  .gtMsgResContent{
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .gtBLHeadBtn .btn-green {
    background-color: #FFBE00;
    color: white;
    padding: 0.5rem 1rem;
    font-size: 13px;
    font-weight: 400;
  }
  .navbar-toggler {
    padding: 0.25rem 1rem;
    font-size: 1.25rem;
    line-height: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: white !important;
    border: 1px solid #fff;
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgb(255, 255, 255);
  }
  .navbar{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .gtTopBg {
    padding-top: 0px;
  }
  .gtDesc h4 {
    margin-top: 10px;
    font-weight: 500;
    font-size: 13px;
    text-align: left;
  }
  .gtDesc p {
    font-size: 12px;
    text-align: left;
  }
  .gtDesc i {
    font-size: 34px;
    margin-top: 20px;
  }
  .gtDesc {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #f9f9f9;
  }
  .gtWhyHome h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-weight: 600;
    color: #303030;
  }
  .gtWhyHome {
    padding-top: 40px;
    padding-bottom: 40px;
    background: #f9f9f9;
  }
  .gtLogRegNow {
    background: transparent;
  }
  .gtSMP15{
    padding: 15px;
  }
  .gtMsgResDet{
    padding-left: 30px;
    padding-right: 30px;
  }
  .gtMsgResDate{
    text-align: left !important;
    padding-left: 30px;
    padding-right: 30px;
  }
  .gtMsgResContent{
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {

  .pincode-input-container > .error {
  left: 44%!important;
}


  #navbarSupportedContent {
    position: relative;
    top: 70px;
    left: -250px;
  }
  .bg-site {
    margin-left: 15px;
  }

  .navbar-toggler {
    padding: 0.25rem 1rem;
    font-size: 1.25rem;
    line-height: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    color: white !important;
    border: 1px solid #fff;
  }
  .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgb(255, 255, 255);
  }
  .navbar{
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .gtLogRegNow {
    background: transparent;
  }
  .gtMainResult .gtMainResAct button {
    padding: 13px 20px;
    font-size: 12px;
  }
  .gtMainResult .gtResDet {
    font-size: 12px;
  }
  .gtMainResult .gtResTag {
    font-size: 12px;
  }
  .gtMsgRes .gtMsgAct button {
    padding: 17px 30px;
  }

  /*date for 21-10-2022*/
  .gtMsgRes img {
    max-height: 140.5px;
    width: 100%;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .gtRegMain p{
  padding-left: 10px;
}
.owl-nav{margin: 0 250px!important;}

.gtRegMain label{
  padding-left: 16px;
}
  .pincode-input-container > .error {
  left: 45%!important;
}
  #navbarSupportedContent {
      line-height: 25px;
      padding-top: 12px!important;
  }
  .gtMainResult .gtResDet {
    font-size: 11px;
  }
  .gtMainResult .gtResTag {
    font-size: 11px;
  }
  .gtMainResult .gtMainResAct button {
    padding: 17.3px 20px;
    font-size: 12px;
  }
  .err-msg  {
    color: #fff!important;
    left: 35px!important;
    top: 250px;
  }
  .navbar-light .navbar-nav a.nav-link {
      padding: 0.65rem!important;
  }
  


  #navbarSupportedContent {
  padding-top: 25px;
  }
}
@media (min-width: 768px){
  .gtLogModal .modal-sm {
    max-width: 430px;
  }

}

.errorMessage, span.required{
  color:red;
  padding:5px;
}

.pagination li {
  display: inline-block;
  list: outside none none;
  margin:5px;
}
.pagination-total-pages {
  display: flex;
  justify-content: space-between;
}
.pagination li a {
  background-color: #ece5e4;
  border-radius: 3px;
  color: #242424;
  display: inline-block;
  line-height: 1;
  padding: 10px 12px;
  text-decoration: none;
  text-align: center;
}
.pagination li a.active,
.pagination li a:hover {
  background-color: #e02c2b;
  color: #fff;
}
.pagination li.pager_first a {
  background-color: #5A6268;
  color: #fff;
}
.pagination li.pager_last a{
  background-color: #5A6268;
  color: #fff;
}

.pagination li.pager_previous a {
  background-color: #E59703;
  color: #fff;
}
.pagination li.pager_next a{
  background-color: #E59703;
  color: #fff;
}
.pagination li.pager_selected_li a{
  background-color: #e02c2b;
  color: #fff;
}

.pagination li.pager_first a:hover {
  background-color: #5A6268;
  color: #fff;
}
.pagination li.pager_last a:hover{
  background-color: #5A6268;
  color: #fff;
}

.pagination li.pager_previous a:hover {
  background-color: #E59703;
  color: #fff;
}
.pagination li.pager_next a:hover{
  background-color: #E59703;
  color: #fff;
}
.pagination li.pager_selected_li a:hover{
  background-color: #e02c2b;
  color: #fff;
}
.pagination a, .pagination span{
  height:35px;
  min-width:35px;
}
.summary {
  padding: 15px 24px 13px;
  width:100%;
}
.total-pages > p {
  margin: 4px 0 0;
}
.shop-border {
  border-bottom: 1px solid #ebebeb;
}
div.dataTables_paginate{
  width:100%;
}
div.dataTables_paginate ul.pagination{
  float: right;
}
.img-shadow {
  box-shadow: 0 8px 17px 0 rgba(0,0,0,0.07),0 6px 20px 0 rgba(0,0,0,0.07) !important;
  border-radius:5px;
}

.ui-pnotify {
  top: 25px;
  right: 25px;
  position: absolute;
  height: auto;
  /* Ensures notices are above everything */

  z-index: 9999;
}
/* Hides position: fixed from IE6 */

html > body > .ui-pnotify {
  position: fixed;
}

.ui-pnotify .ui-pnotify-shadow {
  -webkit-box-shadow: 0px 2px 10px rgba(50, 50, 50, 0.5);
  -moz-box-shadow: 0px 2px 10px rgba(50, 50, 50, 0.5);
  box-shadow: 0px 2px 10px rgba(50, 50, 50, 0.5);
}

.ui-pnotify-container {
  background-position: 0 0;
  padding: .8em;
  height: 100%;
  margin: 0;
}

.ui-pnotify-sharp {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.ui-pnotify-title {
  display: block;
  margin-bottom: .4em;
  margin-top: 0;
}

.ui-pnotify-text {
  display: block;
}

.ui-pnotify-icon,
.ui-pnotify-icon span {
  display: block;
  float: left;
  margin-right: .2em;
}
/* Alternate stack initial positioning. */

.ui-pnotify.stack-topleft,
.ui-pnotify.stack-bottomleft {
  left: 25px;
  right: auto;
}

.ui-pnotify.stack-bottomright,
.ui-pnotify.stack-bottomleft {
  bottom: 25px;
  top: auto;
}

.ui-pnotify-closer,
.ui-pnotify-sticker {
  float: right;
  margin-left: .2em;
}

  .btn-success {
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 12px 36px;
  font-size: 16px;
  font-weight: 500;
  font-color:red;
  color: black;
}
.profile input[type="file"] {
  display: none;
}
.profile1 input[type="file"] {
  display: block;
}
.container.page-body-wrapper{
  margin-left: 90px;
}
h4, .h4 {
  font-size: 15px !important;
}

.modal-backdrop.show {
  opacity: 0.5;
  z-index: 0;
}
.fa_mob {
   font-size: 1.3125rem;
   position: absolute;
   /*left: 90%;*/
   top: 10px;
}

.fa_eml1{
   font-size: 1.3125rem;
   position: absolute;
   right: 23px;
   top: 40px;
}

.fa_mob1 {
   font-size: 1.3125rem;
   position: absolute;
   /*left: 91%;*/
   top: 12px;
}
.fa_mob2{
   font-size: 1.3125rem;
   position: absolute;
   right: 10px;
   top: 14px;
}
.fa_eml2{
   font-size: 1.3125rem;
   position: absolute;
   right: 23px;
   top: 10px;
}
.photo .img-fluid{
  height: 100%!important;
}
.fa.fa-eye-slash {
  font-size: 21px;
   font-size: 1.3125rem;
   position: absolute;
   right: 23px;
   top: 10px;
   font-weight: 400 !important;
   z-index: 1!important;
}
.fa.fa_eyeopen{
  font-size: 21px;
   font-size: 1.3125rem;
   position: absolute;
   right: 23px;
  top: 10px;
   font-weight: 400 !important;
   z-index: 1!important;
}
/*.reg_one{
  left: 92% !important;
  top: 20px !important;
}*/
.foreg_one{
  left: 85%;
  top: 12px;
}


@media (max-width: 320px){

  div.photo .img-fluid {
  height: 270px !important;
}
    a.d-block{
    display: block !important;
  }
    .custom-control-inline {
    margin-right: 0rem!important;
  }
    /*#fa_eml{
     left: 85%!important;

  }*/
  /*#reg-one{
    left: 84%!important;
    top: 20px !important;
  }*/
  #fa_mob {
     left: 81%!important;

  }
  .gtRegMain{
    padding: 1rem;
  }

  /*.fa_mob2{ left: 91%!important;}*/
  .form-control {padding: 0.375rem 1.75rem;}
  /*#eye-cl{
  left: 83%!important;
  }*/
  /*.fa.fa-eye-slash {left: 80%;}*/
  .d-block {display: inline-grid !important;}
  /*#eye-slash{
      left: 78%!important;
      top: 100px!important;
  }*/
  .gtSetEditPass {
  margin-top: 15% !important;
  }


  #new_password {
    /*! margin-top: 4%!important; */
  }
  #fa_mob.fa_mob{
    left: 80% !important;
  }
  /*#fa_eml.fa_eml2{
    left:85% !important;
  }*/
  /*#eye-cl.fa.alig-left{
    left: 83%!important;
    top: 25% !important;
     }*/
     /*.fa.fa_eyeopen{
      left:84% !important;
     }*/

  /*i.fa.fa_eyeopen{
    top:25% !important;
  }*/

  /*i.fa.alig-top {
    top: 25%!important;
  }*/
  
  .Email_error_msg {

  position: relative;
  top: 10px;
  left: 10px!important;

    }

    .agile_featured-profiles .span-tag  {
    bottom: 175px!important;
  }


}
  

@media screen and (min-width:320px) and (max-width:390px) {
  
  #navbarSupportedContent {
    top: 90px !important;
    padding-top: 1px !important;
    padding-bottom: 65px !important;
    left: 0% !important;
  }
  .pincode-input-container > .error {
    left: 120px !important;
    top: 53% !important;
  }
  .error {font-size: 12px!important;}
  #pills-tabContent {
  padding: 1rem;
  }
  .find-section {
  position: absolute;
  top: 30%;
  }


  }


  
@media screen and (min-width:321px) and (max-width:450px) {
    #navbarSupportedContent {
      /*top: 10px!important;
      position: absolute;
      left: 0%!important;
      padding-bottom: 50px!important;*/
      padding-bottom: 95px !important;
    }
    .main-header .header-lower .logo {
    position: absolute!important;

  }
    .search_not {
  position: absolute;
  top: 75px!important;
  left: -3px!important;}
  #pills-tabContent {
    padding: 1.3rem;
  }
  div .navbar {
  margin-bottom: 115px;
  }

  }

@media (max-width: 480px){
    .err-msg {
  top: 73%!important;
}
    .pincode-input-container > .error {
      /*left: 190px;*/
      top: 48%;
    }
    .err-msg-before{
    top: 73%!important;
left: 35px;
}
    div.agile_featured-profiles span.span-tag  {
    bottom: 180px;
  }

  /*#fa_eml{
    left: 90%;
  }*/

  /*.reg_one {
    left: 89%!important;
  }
*/
  #fa_mob {
    /*left: 87%;*/
    top: 10px;
  }
  /*.fa_mob2{
    left: 94%;}*/
  }


     #eye-slash {
    top: 115px;
  }
  #new_password_repeat {
    /*! margin-top: 4%!important; */
  }

    /*#eye-cl.alig-left{
    left: 89%!important;
    top: 25% !important;
     }*/
     /*.fa.fa_eyeopen{
      left:89%;
     }*/
    #reg-one.reg-two {
    top: 45% !important;
  }

  .Email_error_msg {

  position: relative!important;

  left: 35%;
  }
  .fa_mob1 {
    font-size: 1.3125rem;
    position: absolute;
    /*left: 93%!important;*/
    top: 12px;
}



  /*}*/



  @media (max-width: 768px){
    /*#align-wid.form-group{
    width: 295px!important;
  }*/
  /*#fa_eml{
    left: 90%;

  }*/
  /*.reg_one {
    left: 90%!important;
  }*/
  /*#fa_mob {left: 88%;}*/

  .center-768 {
  text-align: center;
}
  }
  @media (min-width:321px) and (max-width:480px) {
    #eye-cl {
    left: 8 9% !important;}
     #eye-slash{
          left: 84%!important;

     }
   #eye-slash{
          top: 105px!important;
     }
  /*   #fa_mob.fa_mob{
    left: 86% !important;
  }*/
  /*#fa_eml.fa_eml2{
    left: 90% !important;
  }
*/
  /*i.fa.alig-top {
    top: 25%!important;
  }*/
  /*#eye-cl.fa.fa-eye-slash.alig-left {left: 89%!important;}*/

  .Email_error_msg {
    position: relative;
    top: 10px;
    left: 10px!important;

    }

    }



   @media (min-width:481px) and (max-width:768px) {
    div.img-tag-lv img {
    margin-left: -75px!important;
  }
    .err-msg  {
    color: #fff!important;
    left: 35px!important;
    top: 225px;
  }

    .container.page-body-wrapper {
    margin-left: 18 px!important;
  }
          i#eye-ope-1.fa.fa-eye{
    top: 30% !important;
    left: 90% !important;
  }
  i#eye-ope-2.fa.fa-eye{
    top: 30% !important;
    left: 90% !important;
  }

        /*i#fab-mob{

     left: 84%;
  }*/
    /*#eye-cl{
  left: 92%!important;}*/
  #mob-ic {
    left: 95%!important;
  }
  #eye-slash{
    left: 85%;
    top: 110px!important;
  }
     /*.fa_eyeopen{
      left: 92%!important;
     }
*/
  #new_password {
    /*! margin-top: 9px!important; */
  }
  /*.reg_one {
    left: 90% !important;}*/
  #fa_mob.fa_mob{
    left: 86% !important;
  }
  #fa_eml.fa_eml2{
    left: 90% !important;
  }
  #reg-one.reg-two{
    top: 42% !important;
  }
  /*#eye-cl.fa_eyeslash.alig-left {
    left: 92% !important;
    top: 25% !important;
  }*/
  /*i.fa.alig-top {
    top: 25%!important;
  }*/
  .Email_error_msg {

  position: relative;
  top: 10px;
  left: 35%;

  }

  

    .fa_mob1 {
    font-size: 1.3125rem;
    position: absolute;
    /*left: 96%!important;*/
    top: 12px;
  }


    }

  @media (max-width: 992px) {

    .btn-center {
      margin-left: 0%!important;
    }
    .btnRegFsubmit {text-align: left;}
    i#eye-ope-1.fa.fa-eye{
    top: 30% !important;
    left: 90% !important;
  }
  i#eye-ope-2.fa.fa-eye{
    top: 30% !important;
    left: 90% !important;
  }

    /*i#fab-mob.fas{

     left: 84%;
  }*/

  /*#reg-one {
    left: 92%;
  }*/
  /*.fa_mob2 {left: 93%;}*/
   /*#mob-ic {
    left: 93%;
     }*/
     /*.alig-left {
    left: 87%!important ;
    top: 27%!important;
  }*/
  /*.fa_eml2 {
    left: 92%!important;}*/
    /*.fa_mob {
    left: 90%!important;}*/

     #new_password_repeat {
    /*! margin-top: 3%!important; */
  }
  #new_password {
    /*! margin-top: 8px!important; */
  }
  /*#fa_mob {
    left: 90% !important;
  }*/
  /*#fa_eml{
    left: 92% !important;
  }*/
  .reg-two {
    top: 45% !important;
  }

   #eye-slash.fa.alig-dow{
    top: 110px;
  }
  /*i.fa.alig-top {
    top: 25%!important;
  }*/
  /*i.fa.fa_eyeopen {left:88%;}*/

  }
  @media (min-width:769px) and (max-width:992px) {

  /*#eye-cl.fa-eye-slash  {
    left: 88%!important;
  }*/
  .container.page-body-wrapper {
    margin-left: 0px!important;
  }
  .agile_featured-profiles .span-tag {left: 85%!important;}
  .err-msg-before {top: 195px;}


    }


  @media (max-width: 1200px){
    

  .container.page-body-wrapper {
    margin-left: 20px;
  }

  /*i.fa-email-alig{
    position: absolute;
    left: 89%;
    top: 45% !important;
  }*/

  /*.fa_eml2 {
    left: 92%;
    top: 25%;
    position: absolute;
  }*/
  /*#reg-one{
    left: 91%;
  }*/
  /*.fa_mob {
    left: 90%;
    top: 10px;
  }*/
  /*.fa_mob2 {left: 94%;}*/
  /*#eye-cl{
    left: 90%!IMPORTANT;
    top: 27%!important;
     }*/
     #eye-slash{
          left: 85%;
          top: 115px;
     }
     #new_password {
    /*! margin-top: 8px; */
  }
  #new_password_repeat {
    /*! margin-top: 3%; */
  }
  .reg-two{

    top: 50%!important;

  }
  /*i.fa.alig-top {
    top: 25%!important;
  }*/
  /*.fa.fa_eyeopen {left: 90%;}*/

  .Email_error_msg {

  position: relative;
  top: 10px;
  left: 35%;

   }


  }


  label{
    display: inherit;
  }
  .gtSetEditPass{
    margin-top: 5%;
  }

  .close:not(:disabled):not(.disabled), .close:not(:disabled):not(.disabled):focus {
    color: #000;
    margin-top: -31px !important;
  }

  @media (min-width: 769px){

    .gtConDet{
      margin-top: 35px;
    }
    .fa_mob1 {
    font-size: 1.3125rem;
    position: absolute;
    /*left: 97%!important;*/
    top: 12px;
  }

  }


   #new_password{
    /*! margin-top: 2%; */
  }
  #new_password_repeat{
    /*! margin-top: 3%; */
  }

  .reg-two{

    top: 50%!important;

  }

  /*.fa.fa_eyeopen{
    top:25%!important;
  }*/
  /*i.fa.alig-top {
    top: 25%!important;
  }*/

#eye-slash.fa.alig-dow{
position: absolute;
left: 85%;
top: 108px;}

i#eye-slash-3.alig-dow{
  position: absolute;
  right: 23px;
  top:  240px;
  font-weight: 400 !important;
  z-index: 1 !important;
}

i#eye-slash-2.alig-dow{
  position: absolute;
  right: 23px;
  top:  140px;
  font-weight: 400 !important;
  z-index: 1 !important;
}

i#eye-slash-1.alig-dow{
  position: absolute;
  right: 23px;
  top:  43px;
  font-weight: 400 !important;
  z-index: 1 !important;
}

@media (max-width: 480px){

  /*i#eye-slash-1.alig-dow{
  left: 83%!important;

  }*/
  .container.page-body-wrapper {
    margin-left: 0px!important;
  }
  .btn-top-ali{
    margin-top: 15px;
  }

  #navbarSupportedContent {
    position: relative;
    left: -170px;
    top: 60px;
  }

  /*i#eye-slash-2.alig-dow{
  left: 83%!important;

  }*/

  /*i#eye-slash-3.alig-dow{
  left: 83%!important;
  top: 63%!important;

  }*/


  .err-msg  {
    color: #fff!important;
    left: 35px!important;
    top: 318px;
  }


  i#eye-ope-1.fa.fa-eye{
    top: 30% !important;
    left: 90% !important;
  }
  i#eye-ope-2.fa.fa-eye{
    top: 30% !important;
    left: 90% !important;
  }
  }







@media (max-width: 320px){

  /*Date for 21-10-2022*/

#navbarSupportedContent {
  top: 0px!important;
}


.err-msg  {
  color: #fff!important;
  left: 35px!important;
  top: 335px;
}

.custom-file-label {
   width: 90%!important;
}
.form-control {
padding: 0.5rem 0.5rem!important  ;
}
.pincode-input-text, .form-control.pincode-input-text {
width: 30px!important;

}
 .form-control {
  font-size: 0.8rem !important;}

  /*i#eye-slash-3.alig-dow {
    top: 56% !important;
  }*/
  /*i#eye-slash-2.alig-dow {
    top: 33% !important;}*/
  /*i#eye-slash-1.alig-dow {
    top: 10% !important;}*/
#User_user_password_em_ {
  position: absolute;
  top: 70%!important;
}

  /*i.fa-email-alig{
  position: absolute;
left: 85%!important;
top: 40% !important;
}
*/
/*    i#eye-slash-1.alig-dow{
left: 80%!important;
}*/

i#eye-ope-1.fa.fa-eye{
  top: 30% !important;
  left: 90% !important;
}
i#eye-ope-2.fa.fa-eye{
  top: 30% !important;
  left: 90% !important;
}
/*i#eye-slash-2.alig-dow{
left: 80%!important;

}*/
/*i#eye-slash-3.alig-dow{
left: 80%!important;

}*/
/*#fab-mob.fas.fa-mobile-alt.fa_mob{

   left: 83%!important;
}*/
  }

  /*i#reg-one.fa_eyeslashone {
    top: 10px!important;
  }*/

/*i#fab-mob.fas.fa_mob{

   left: 87%!important;
}*/

i#eye-ope-1.fa.fa-eye{
  top: 30% !important;
  left: 90% !important;
}
i#eye-ope-2.fa.fa-eye{
  top: 30% !important;
  left: 90% !important;
}
.close.icon-alig-cor{
position: absolute!important;
top: 10px !important;
right: 2px !important;
}

/*i.fa-email-alig{
  position: absolute;
left: 90%;
top: 45% !important;
}*/
div.label-cont-3{
  margin-top: 0px !important;
}



#User_user_password_em_{
position: absolute;
top: 75%;}
.custom-control label{
  display: contents;
}
.btn-center {
    margin-left: 33%;

  }


@media (min-width: 1201px) and (max-width: 1423px) {
  .btnSucPost{
    margin-right: 70px;
  }
  .container.page-body-wrapper {
  margin-left: 95 px!important;
  }

.Email_error_msg {

  position: relative!important; 
  top: 10px;
  left: 35%;
}
#main-header .logo {
  margin-left: -50px!important;
}

}

  /* jhfgkhlkh  */
@media (min-width: 1200px) and (max-width: 1423px) {
  .margin-left-bt{
  margin-left: -200px;
  }
  .fa_mob1 {
  font-size: 1.3125rem;
  position: absolute;
  /*left: 94%!important;*/
  top: 12px;
  }
  .err-msg  {
  color: #fff!important;
  left: 35px!important;
  top: 180px;
  }

  /*.gtRegMain p{
  padding-left: 20px;
  }*/

  .gtRegMain label{
  /*padding-left: 26px;*/
  }
  #navbarSupportedContent {
  padding-top: 20px;
}
}

  /*date for 29-3-2022*/

  .fa_mob1 {
    font-size: 1.3125rem;
    position: absolute;
    /*left: 94%!important;*/
    top: 12px;
  }

  .pt-20{
    padding-top: 10px;
    padding-bottom: 10px;
  }
    .profile1{
        margin-bottom: 35px;
  }

  a:hover {
    text-decoration: none;
  }

  .align-right{
    float: right;
  }
  .submit-left{
    float: right;
  }

  .align-wid{
    width: 108%;
    margin-bottom: 50px;
  }

  #Userdetails_ud_horoscope_pic span{
    margin-left: 20px!important;
  }

  .input-span-txt{
  margin-left: 10px;
  }

  #gallery_image_name__F1{
    display: none;
    padding: 30px;
  }

  .MultiFile-remove{
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    max-width: 100%;
    height: auto;
    color: #495057;
  }
  .MultiFile-label{
    margin-top: 10px;
  }
  .custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
  }
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .custom-file-input {
    /*position: relative!important;*/
    z-index: 2!important;
    width: 100%!important;
    margin: 0!important;
    opacity: 0!important;
  }
  .custom-control-label::before, .custom-file-label, .custom-select {
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  }
  .custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0 ;
    z-index: 0;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
  }
  .offset {
    margin: 5px!important;
  }
  .mt-20{
    margin-top: 10px;
  }


/*date 4-1-2022*/

.gtRegMain, .gtMobVerify, .gtLogMain, .border-contact, .img-fluid, .gtSucBody, .gtSerBody{
      border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
}

h4.gtRegDivider {
        margin-top: 18px!important;
}
.custom-file-label{
  width: 95%;
}
.mleft-15{
   margin-left: 15px;
}
.input-span-txt{
        margin-top: -20px!important;
}

#Userdetails_ud_horoscope_pic{
      margin-left: 20px;
}
.horocss {
  float: left;
  width: 10;
  margin: 2px 10px;
  font-size: 14px;
  font-weight: 500;
  color: red;
}
.brd1 {
  background-color: #FFFFFF;
  border: 1px solid #CCCCCC;
  font-size: 16px;
  text-align: center;
  font:bolder;
  width: 85px;
  color:#CC3300;
}

/*Date for 29-3-2022*/
  
 .fa_mob1 {
  font-size: 1.3125rem;
  position: absolute;
  /*left: 97%;*/
  right: 25px;
  top: 15px;
}

/*Date for 30-3-2022*/
  
  .card-img-top img.img-fluid {
        max-width: 100%!important;
      height: 250px!important;
  }

.gtSucBody .gtSucDisplay .card .card-body {
  min-height: auto!important;
}
  
/*Date for 8-4-2022*/
 @media only screen and (max-width: 960px) {
.gtTopBg {
  background-size: contain!important;
   }
  }


/*New Index page Design*/


/*** 

====================================================================
  Main Header style
====================================================================

***/
  
.main-header{
  position:absolute;
  left:0px;
  top:0px;
  z-index:999;
  width:100%;
  border-bottom: 1px solid #eee;
}
.main-header .header-top{
  position:relative;
  border-bottom:1px solid rgba(0,0,0,0.30);
  padding:5px 0px;
  background:#252525;
}
.main-header .header-top .top-left{
  position:relative;
  float:left;
}
.main-header .header-top ul li{
  position:relative;
  display:inline-block;
  margin-left:20px;
}
.main-header .header-top .top-left ul li{
  margin-right:20px;
  margin-left:0px;  
}
.main-header .header-top ul li a{
  position:relative;
  display:block;
  line-height:20px;
  color:#cccccc;
  font-size:12px;
  transition:all 0.7s ease;
  -moz-transition:all 0.7s ease;
  -webkit-transition:all 0.7s ease;
  -ms-transition:all 0.7s ease;
  -o-transition:all 0.7s ease;
}
.main-header .header-top ul li .fa{
  position:relative;
  top:1px;
  font-size:13px;
  padding-right:5px;  
}
.main-header .header-top ul li a:hover{
  color:#ffffff;
}
.main-header .header-top .top-right{
  position:relative;
  float:right;
}
.main-header .header-lower{
  position:relative;
  padding:0px 0px;
  background:#ffffff;
  width:100%;
  left:0px;
  top:0px;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}
.fixed-header .header-lower{
  position:fixed;
  padding:0px !important;
}
.main-header .header-lower .outer-box{
  position:relative;
  /*padding:0px 150px 0px 0px;*/
}
.main-header .header-lower .search-box-btn{
  position:absolute;
  right:80px;
  top:35px;
  width:40px;
  padding-left:10px;
}
.main-header .header-lower .search-box-btn .search-btn{
  position:relative;
  display:block;
  font-size:18px;
  color:#ffffff;
  line-height:30px;
  width:30px;
  height:30px;
  text-align:center;
  cursor:pointer;
}

.main-header .header-lower .search-box-btn .search-btn:hover{
  color:#f1f1f1;
}
.main-header .header-lower .logo{
  position:relative;
  float:left;
  /*padding-right:10px;*/
  left: 0px;
  background:#FFBE00;
  line-height: 85px;
}
.main-header .header-lower .logo:before{
  content:'';
  position:absolute;
  right:100%;
  top:0%;
  width:1000px;
  height:100%;
  background:#FFBE00; 
}
.main-header .header-lower .logo:after{
  content:'';
  position:absolute;
  left:70%;
  top:0%;
  width:100px;
  height:100%;
  background:#FFBE00;
  transform:skewX(-25deg);
  -webkit-transform:skewX(-25deg);
  -ms-transform:skewX(-25deg);
  -o-transform:skewX(-25deg);
  -moz-transform:skewX(-25deg); 
}
.main-header .header-lower .logo img{
  position:relative;
  display:inline-block;
  max-width:100%;
  z-index:1;
}
.main-menu{
  position:relative;
  float:right;
  padding:25px 0px; 
}
.main-menu .navbar-collapse{
  padding:0px;  
}
.main-menu .navigation{
  position:relative;
  float:right;
  margin:0px;
  font-family: inherit;
}
.main-menu .navigation > li{
  list-style: none;
  position:relative;
  float:left;
  padding:0px;
  margin-right:40px;  
}
.main-menu .navigation > li > a{
  position:relative;
  display:block;
  padding:10px 0px;
  font-size:14px;
  color:#FFBE00;
  line-height:20px;
  font-weight:700;
  text-transform:uppercase;
  opacity:1;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}
.main-menu .navigation > li:hover > a,
.main-menu .navigation > li.current > a,
.main-menu .navigation > li.current-menu-item > a{
  color:#1a1a1a;
  opacity:1;
}
.main-menu .navigation > li > ul{
  position:absolute;
  left:0px;
  top:160%;
  width:200px;
  padding:0px;
  border:1px solid #ffffff;
  z-index:100;
  background:#FFBE00;
  visibility:hidden;
  opacity:0;
  border-radius:0px 5px 5px 5px;
  -ms-border-radius:0px 5px 5px 5px;
  -webkit-border-radius:0px 5px 5px 5px;
  -moz-border-radius:0px 5px 5px 5px;
  -o-border-radius:0px 5px 5px 5px;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}
.main-menu .navigation > li > ul:before{
  font-family: 'FontAwesome';
  content: "\f0d8";
  position:absolute;
  left:15px;
  top:-19px;
  padding-top:4px;
  display:block;
  width:100%;
  width:100%;
  height:20px;
  display:block;
  color:#FFBE00;
  line-height:20px;
  font-size:28px;
  z-index:5;  
}
.main-menu .navigation > li:hover > ul {
  top:120%;
  opacity:1;
  visibility:visible; 
}
.main-menu .navigation > li > ul > li{
  position:relative;
  list-style: none;
  float:none;
  width:100%;
  border-bottom:1px solid rgba(255,255,255,0.50);
}
.main-menu .navigation > li > ul > li:last-child{
  border:none;  
}
.main-menu .navigation > li > ul > li > a{
  position:relative;
  display:block;
  padding:10px 10px;
  font-weight:normal;
  font-size:13px;
  color:#ffffff;
  font-weight:500;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}
.main-menu .navigation > li > ul > li:hover > a{
  color:#ffffff;
  background-color:#F1A1DC;
}
.main-menu .navigation > li > ul > li.dropdown > a:after{
  font-family: 'FontAwesome';
  content: "\f0da";
  position:absolute;
  right:10px;
  top:8px;
  width:10px;
  height:20px;
  display:block;
  color:#ffffff;
  line-height:20px;
  font-size:16px;
  text-align:center;
  z-index:5;  
}
.main-menu .navigation > li > ul > li.dropdown:hover > a:after{
  color:#1a1a1a;  
}
.main-menu .navigation > li > ul > li.dropdown:hover > a:after{
  color:#ffffff;  
}
.main-menu .navigation > li > ul > li  > ul{
  position:absolute;
  left:110%;
  top:-1px;
  width:180px;
  padding:0px;
  border:1px solid #ffffff;
  border-top-width:2px;
  z-index:100;
  background:#FFBE00;
  visibility:hidden;
  opacity:0;
  border-radius:0px 5px 5px 5px;
  -ms-border-radius:0px 5px 5px 5px;
  -webkit-border-radius:5px 0px 5px 5px;
  -moz-border-radius:0px 5px 5px 5px;
  -o-border-radius:0px 5px 5px 5px;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
}
.main-menu .navigation > li > ul > li:hover > ul {
  left:100%;
  opacity:1;
  visibility:visible; 
}
.main-menu .navigation > li > ul > li  > ul > li{
  position:relative;
  float:none;
  width:100%;
  border-bottom:1px solid #ffffff;
}
.main-menu .navigation > li > ul > li  > ul > li:last-child{
  border:none;  
}
.main-menu .navigation > li > ul > li  > ul > li > a{
  position:relative;
  display:block;
  padding:4px 10px;
  font-weight:normal;
  font-size:13px;
  color:#ffffff;
  font-weight:500;
  transition:all 500ms ease;
  -moz-transition:all 500ms ease;
  -webkit-transition:all 500ms ease;
  -ms-transition:all 500ms ease;
  -o-transition:all 500ms ease;
  list-style: none;
}
.main-menu .navigation > li > ul > li  > ul > li:hover > a{
  color:#ffffff;
  background-color:#F1A1DC;
}
.main-menu .navbar-collapse > ul li.dropdown .dropdown-btn{
  position:absolute;
  right:10px;
  top:6px;
  width:34px;
  height:30px;
  border:1px solid #ffffff;
  /*background:url(../images/icons/submenu-icon.png) center center no-repeat;*/
  background-size:20px;
  cursor:pointer;
  z-index:5;
  display:none;
  border-radius:3px;
  -webkit-border-radius:3px;
  -ms-border-radius:3px;
  -o-border-radius:3px;
  -moz-border-radius:3px;
}
.appoinment-btn {
  position: absolute;
  right: 0;
  top: 25px;
}
.appoinment-form-outer {
  position:relative;
  padding:25px 30px 40px;
  background:#f6f6f6;
  border-radius:2px;
  font-family:'Open Sans',sans-serif;
}
.appoinment-form-outer h3 {
  font-size:24px;
  font-weight:600;
  margin-bottom:30px; 
}
.appoinment-form-outer hr {
  margin-bottom:40px;
  border-color:#e0e0e0; 
}
.appoinment-form-outer .form-group {
  position:relative;
  margin-bottom:30px; 
}
.appoinment-form-outer input[type="text"],
.appoinment-form-outer input[type="email"],
.appoinment-form-outer input[type="tel"],
.appoinment-form-outer input[type="url"],
.appoinment-form-outer input[type="password"],
.appoinment-form-outer select,
.appoinment-form-outer textarea {
  position:relative;
  display:block;
  width:100%;
  line-height:34px;
  padding:8px 20px;
  background:#ffffff;
  border:1px solid #e0e0e0;
  height:52px;
}
.appoinment-form-outer select {
  cursor:pointer; 
}
.appoinment-form-outer .field-label {
  position:relative;
  display:block;
  font-weight:600;
  font-size:18px;
  color:#252525;
  line-height:28px;
  margin-bottom:10px;
}
.appoinment-form-outer .field-label .required {
  color:#ff0000;
  font-size:14px; 
}
.appoinment-form-outer .radio-select {
  position:relative;
  float:left;
  margin-right:30px;
  margin-bottom:5px;
  line-height:50px;
  outline: none;
}
.appoinment-form-outer .radio-select:last-child {
  margin-left:25px;
}
.appoinment-form-outer .radio-select label {
  color: #252525;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  text-align: center;
  text-transform: uppercase;
}
.appoinment-form-outer .radio-select input[type="radio"]:checked+label {
  color:#FFBE00;
}
.appoinment-form-outer button {
  padding:9px 30px; 
}
.modal-backdrop {
  z-index: 0;
}
.title-border::after {
    background: #FFBE00 none repeat scroll 0 0;
    content: "";
    height: 2px;
    left: 0;
    position: absolute;
    top: 30px;
    width: 50px;
}
.dl-horizontal dd {
    margin-bottom: 30px;
}
/*Search Box*/

.main-header .search-box{
  position:relative;
  padding:15px 0px;
  border-bottom:1px solid rgba(255,255,255,0.30);
  background:#944738;
  color:#ffffff;
  display:none; 
}

.main-header .search-box .search-title{
  position:relative;
  float:left;
}
.main-header .search-box .search-title h2{
  font-size:20px;
  font-weight:600;
  line-height:36px; 
}
.main-header .search-box .search-form{
  position:relative;
  float:right;
  max-width:350px;
  overflow:hidden;
}
.main-header .search-box .form-group{
  position:relative;
  display:block;
  padding:0px;
  margin:0px;
  width:100%;
  overflow:hidden;  
}
.main-header .search-box .form-group input[type="search"],
.main-header .search-box .form-group input[type="text"]{
  position:relative;
  display:block;
  width:100%;
  padding:8px 40px 6px 15px;
  border:2px solid rgba(255,255,255,0.30);
  color:#ffffff;
  font-weight:500;
  font-size:16px;
  line-height:22px;
  background:none;
}
.main-header .search-box .form-group input[type="search"]:focus,
.main-header .search-box .form-group input[type="text"]:focus{
  border-color:rgba(255,255,255,0.70);  
}
.main-header .search-box .form-group .search-submit{
  position:absolute;
  right:1px;
  top:1px;
  width:40px;
  height:38px;
  background:#5d1d10;
  display:block;
  padding:0px 10px;
  color:#ffffff;
  font-size:16px;
  line-height:38px;
}
.main-header .search-box .form-group .search-submit:hover{
  background:#343434; 
}

/*** 


/*Date for 25-5-2022*/

.thm-btn {
  background: #FFBE00;
  background-color: #FFBE00;
  border-radius: 30px;
  display: inline-block;
  color: #fff;
  font-size: 12px;
  font-family: inherit;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px 35px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transition: all .3s ease;
}
.thm-btn-1 {
  background-color: #fff;
  border-color: #FFBE00!important;
  color: #FFBE00;
  border-radius: 30px;
  display: inline-block;
  font-size: 12px;
  font-family: inherit;
  text-transform: uppercase;
  font-weight: bold;
  padding: 12px 35px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: 2px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  transition: all .3s ease;
}
.appoinment-btn {
  position: absolute;
  right: 0;
  top: 25px;
}
.thm-btn:hover, .thm-btn.inverse {
  background-color: #fff;
  border-color: #FFBE00;
  color: #FFBE00;
}

.finder-caption h2, .finder-caption p {
  margin-bottom: 20px;
  color: #fff;
}
.finderform {
  background-color: rgba(125, 125, 125, 0.6);
  padding: 30px;
}
.find-section {
  text-align: center;
  color: #fff;
}
select.form-control {
  height: 48px;
  background-color: #fdfdfb;
  margin-bottom: 0px;
  border: 1px solid #e9e6e0;
  color: #2b212f;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  color: #706a68;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: 95% 50%;
  background-repeat: no-repeat;
  /*background-image: url(../images/arrow.png);*/
  padding-right: 15px;
  -webkit-box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, .075);
  font-family: inherit;
}
.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  padding-right: 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}
.form-group {
  margin-bottom: 25px;
}
.btn-primary {
  color: #fff;
  background-color: #FFBE00;
  border-color: #FFBE00;
}
.btn-primary:hover {
  background-color: #fff;
    color: #FFBE00;
    border: 1px solid #FFBE00!important;
    transition: all 0.3s ease;
}
.find-section {
  position: absolute;
  top: 60%;
  margin: 0 auto;
  left: 0%;
  right: 0%;
  text-align: center;
  z-index: 1;
}
.finder-caption h1 {
  margin-bottom: 10px;

}


.project_completed_number .countdoircle {
  background:#FFBE00;
}
.countdoircle {
  position:relative;
  background:#fff;
  box-shadow:1px 1px 10px 1px #cbd8e7;
  text-align:center;
  vertical-align:middle;
  padding:25px 10px;
  /*border-radius:10px;*/
}
.countdoircle::before, a.btn-lets-wow::before {
  width: 100%;
  height: 0%;
  transform: translate(-50%,-50%) rotate(-45deg);
  background: #FFBE00;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transition: all 1.2s ease;
  z-index: 1;
}
.countdoircle p {
   margin:0;
   padding:0;
   color:#FFBE00;
   font-size:75px;
   line-height:1;
   font-weight:700;
}
.countdoircle span {
   display:block;
   font-size:16px;
   margin:0;
   font-weight:400;
   color:#212735;
}
.business_partner_number p,
,
.happy_clients_number p {
  color:#212735;
}
.business_partner_number span,
.happy_clients_number span {
  color:#212735;
}
.award_wins_number .countdoircle {
  background:#FFBE00;
}
.award_wins_number p,
.project_completed_number p {
  color:#fff;
}
.award_wins_number span,
.project_completed_number span {
  color:#fff;
}
.award_wins_number,
.business_partner_number,
.happy_clients_number,
.project_completed_number {
  width:31%;
  float:left;
  margin:0 1%;
}
.clients_project {
  padding:25px 0;
  /*background:#f6f7fb;*/
}
.stach-tag {
  margin-top: 15px;
  margin-bottom: 15px;
}
.award_wins_number h3 {
  color: #fff;
}
.w3l_find-soulmate {
  padding: 60px 0;
  background-color: rgba(0, 0, 0, 0.89);
}
.w3_soulgrid i {
  font-size: 25px;
  color: #FFBE00;
  border: 1px solid #eaeaea;
  padding: 25px 0;
  border-radius: 60px;
  width: 70px;
  height: 70px;
  text-align: center;
  box-shadow: 1px 1px 1px #FFBE00;
}
.w3_soulgrid:hover h3 {
    color: #FFBE00;
}

/*Be inspired section */

.well-box {
  background-color: #fff;
  padding: 28px;
  border: 1px solid #e9e6e0;
  margin-bottom: 30px;
  box-shadow: 0px 3px 15px 2px rgba(150, 147, 136, 0.5);
}
.agile_featured-profiles h2 {
  margin-bottom: 20px;
}
.agile_featured-profiles p {
  margin-bottom: 25px;
}
.well-box h3 {
  color: #FFBE00;
}
.well-box p span i {
  color: #FFBE00;
  font-size: 16px!important;
}

a:focus, a:hover {
  text-decoration: none;
}
.ml-4 {
  margin-left: 10px;
}

header {
  padding: 0px 0;
}

.w3_soulgrid {
  padding: 35px 15px;
  margin: 5% 1%;
}
.margin-auto {
  margin: 0 auto 0;
}
.finderform {
  padding: 20px;
}
.finderform button {
  width: 100%;
}
.w3_soulgrid p {
  font-size: 14px;
} 
a {
  text-decoration: none!important;
}
.card-body {
  padding: 10px 16px 15px 16px;
}
.card-body h4 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 24px!important;
  color: #000;
  font-weight: 600;
}
#flexiselDemo3 li {
  list-style: none;
  line-height: 40px;
}
.img-tag-lv img {
  margin: 0 auto 0!important;

}

/*CUstom card design*/

.box{
    color: #fff;
    background: #2b3c4e;
    font-family: inherit;
    overflow: hidden;
    position: relative;
    transition: all 0.3s ease-in-out;
}
.box:hover{ box-shadow: 0 0 10px rgba(0, 0, 0, 0.4); }
.box img{
    width: 100%;
    height: auto;
    transition: all 0.3s ease;
}
.box:hover img{
    opacity: 0.6;
    filter: grayscale(100%);
}
.box .box-content{
    color: #fff;
    background: linear-gradient(to left, transparent,transparent,rgba(34,47,61,0.9),#222f3d,#222f3d);
    text-align: left;
    width: 100%;
    padding: 5px 15px 5px 15px;
    transform: translateY(-50%) scaleY(0);
    position: absolute;
    bottom: -65px;
    right: 0;
    transition: all 0.3s ease-in-out;
}
.box:hover .box-content{ transform: translateY(-50%) scaleY(1); }
.box .title{
    font-size: 20px;
    font-weight: 800;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0 0 4px;
}
.box .post{
    font-size: 15px;
    font-style: italic;
    text-transform: capitalize;
    margin: 0 0 5px;
    display: block;
}
.box .icon{
    padding: 0;
    margin: 0;
    list-style: none;
    transform: translate(-100%, -50%);
    position: absolute;
    top: 50%;
    left: 0;
    transition: all 0.3s ease-in-out;
}
.box:hover .icon{ transform: translate(0, -50%); }
.box .icon li a{
    color: #222f3d;
    background: #fff;
    font-size: 20px;
    text-align:center;
    line-height: 40px;
    height: 40px;
    width: 40px;
    display: block;
    transition: all 0.3s ease;
}
.box .icon li  a:hover{ box-shadow: 0 0 5px #222f3d inset; }
@media only screen and (max-width:990px){
    .box { margin: 0 0 30px; }
}


/*Date for 26-5-2022*/

.w3_soulgrid {

  margin: 20px auto 10px;
}


/*Box-condent-vendor-list*/

.wrap {
  height: 100%; 
}
.card {
  display: flex;
  flex: 0 0 auto;
  background: #fff;
  max-width: 700px;
  margin: 30px 0;
  border-radius:  ;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}
.card-pic-wrap {
  border-radius: $b-r 0 0 $b-r;
  width: 300px;
  flex: 0 0 auto;
  position: relative;
  /*background: linear-gradient(to bottom, #9fd483, #8dc26f);*/
}
  .card-pic-wrap img {
    position: absolute;
    /*bottom: 3em;*/
    left: 0%;
    margin-left: 0px;
    width: 350px;
}
.card-content {
  padding: 3em 4em 2em;
}
.card-content h3 {
  font-family: inherit;
  font-weight: bold;
  font-size: 2.5em;
  margin: 0 0 1em;
}
.href-tag {
  background: #8dc26f;
  color: #fff;
  padding: 0 25px;
  height: 50px;
  font-size: 0.8em;
  letter-spacing: 1px;
  line-height: 50px;
  display: inline-block;
  border-radius: 25px;
  text-decoration: none;
  margin-top: 1.5em;
  text-transform: uppercase;
  border: 1px solid rgba(0,0,0,0.1);
  }
  .href-tag:hover {
    background: #70a751;
  }

.box-shadow-tag  {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.61), 0 1px 2px rgba(0,0,0,0.24);
  margin: 10px;
}
.agile_featured-profiles p {
  font-size: 18px;
}
.card-body ul li span {
  float: right;
}
.card-body ul li {
  font-size: 16px;
  font-weight: 600;
  color: #000;
}
.card-body {
  /*box-shadow: 1px 11px 15px -7px rgba(0,0,0,0.75);*/
}
.agile_featured-profiles h2 {
  font-size: 28px;
  margin-bottom: 40px;
  font-weight: 600;
}
.agile_featured-profiles .span-tag {
  border: 2px solid #FFBE00;
  border-radius: 20px;
  padding: 2px 20px;
  color: #FFBE00;
  font-weight: 800;
  position: relative; 
  bottom: 90px;
  left: 70%;
  font-size: 12px;
}


  /*FOOTER*/
  .footer{
  background-color:rgba(0, 0, 0, 0.89);
  width:100%;
  display:flex;
  align-items:top;
  padding: 4rem 0;
  }

  footer p {
    color: #fff;
  }

  .footer section{
  padding:1rem 2rem;
  width:100%;
  vertical-align:top;
  }

  .footer section h3{
  color:#fff;
  }

  .footer section a{
  color: #fff;
  display:flex;
  align-items:center;
  white-space:nowrap;
  margin:15px 0;
  }

  .footer section a:hover{
  color: #fff;
  }

  .footer section:nth-child(2) a:before,.footer section:nth-child(3) a:before{
  content:"";
  font-family:"FontAwesome";
  margin-right:5px;
  color: #fff;
  }

  .footer section a span{
  margin:0 10px;
  }

  .sub-footer{
  background-color: #FFBE00;
  text-align:Center;
  color:#fff;
  padding:1rem 0;
  }

  @media (max-width:1020px){
  .footer{
  display:block;
  }
  .footer section{
  padding:1rem 1rem;
  }
  .footer section:nth-child(2), .footer section:nth-child(3){
  width:49%;
  display:inline-block;
  }
  }

/*Section for why*/

  .wpo-about-img {
  padding: 20px;
  background: #86a0b6;
}

.ab-shape {
  position: absolute;
  left: -35%;
  bottom: 0;
  z-index: -1;
}
.wpo-about-icon-content ul li {
  padding: 6px 0;
    padding-left: 0px;
  position: relative;
  padding-left: 20px;
}
.wpo-about-icon-content ul li::before {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  content: "";
  background: #FFBE00;
  border-radius: 50%;
}
.about-single-item {
  position: absolute;
  left: 0;
  top: 0;
  transition: all 5s;
  transform: rotate(10deg);
}
.wpo-about-wrap {
  position: relative;
  z-index: 1;
}
.ab-shape img {
  height: auto;
  max-width: 100%;
  border: none;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.wpo-about-item {
  max-width: 450px;
}
.wpo-about-icon-content ul {
  list-style: none;
}

/*section transition*/

.section-title-img {
  max-width: 130px;
  margin: 0 auto;
  position: relative;
  text-align: center;
}
.section-title-img img {
  width: 50px;
}

.section-title-img::before {
  position: absolute;
  left: -70px;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 100px;
  height: 1px;
  background: #86a0b6;
}

.section-title-img::after {
  position: absolute;
  top: 50%;
  right: -70px!important;
  left: auto!important;
  transform: translateY(-50%);
  content: "";
  width: 100px;
  height: 1px;
  background: #86a0b6;

}


/*Style sheet css for index*/



.w3layouts_featured-profiles {
  padding: 70px 0px 50px;
} 

.w3l_find-soulmate h3 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 35px;
}
.w3_soulgrid {
  border: 1px solid #eee;
  padding: 30px 15px;
  /*width: 23%;*/
  /*margin: 2% 1%;*/
  background-color: #FFF;
}

.w3_soulgrid h3 {
  font-size: 25px ;
  color: #ec3434;
  margin: 30px 0 0px 0;
  text-transform: uppercase;
}
.w3_soulgrid p {
  color: #000000;
  font-size: 15px;
  line-height: 25px;
  margin-top: 15px;
  letter-spacing: 1px;
  font-weight: bold;
}
.agile_featured-profiles h2 {
  font-size: 35px;
  letter-spacing: 1px;
  color: #000;
  margin-bottom: 25px;
  text-align: center;
}
.profile-image {
  position: relative;
  margin: 10px 0px;
}
 
.agile-assisted-service {
  background: url(../images/servicebg.jpg) no-repeat 0px 0px;
  background-attachment: scroll;
  background-size: auto;
  background-size: cover;
  min-height: 400px;
  background-attachment: fixed;
  padding-top: 80px;
  padding-bottom: 80px;
}

.agile-assisted-service h4 {
  font-size: 35px!important;
  color: #fff;
  margin-bottom: 35px;
  letter-spacing: 1px;
}

.agile-assisted-service p {
  font-size: 20px!important;
  color: #e0e0e0;
  letter-spacing: 1px;
  font-weight: 300;
  margin: 0 auto 50px;
  width: 45%;
  line-height: 40px;
}

.agile-assisted-service a {
  font-size: 14px;
  color: #e0e0e0;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-decoration: none;
  background: #FFBE00;
  padding: 10px 25px;
}

.wpo-about-img {
  padding: 20px;
  background: #86a0b6;
}
.wpo-about-img img {
  width: 100%;
}
.profile-image img {
  width: 100%;
}
.card-body ul {
  padding-left: 0!important;
}
.navbar-light .navbar-nav a.nav-link {
  color: #FFBE00 !important;
  font-size: 16px;
  font-weight: 800;
}

@media (max-width: 800px) {

  #navbarSupportedContent {
  float: left!important;
  /*padding-top: 80px!important;*/
  padding-bottom: 20px;
}

}

#navbarSupportedContent {
  float: right;
  position: relative;
  right: 0;
  padding-top: 12px;  
}

@media (min-width: 1200px) {
.container {
  max-width: 1220px;
}
}

.align-cen {
  align-items: center;
  display: flex;
}
.btn-green {
  background-color: #FFBE00;
}
.btn-green:hover, .btn-green:focus {
  background-color: #fff!important;
  color: #FFBE00;
  border: 1px solid #FFBE00;
  transition: all 0.3s ease;
  /*font-weight: 800;*/
}

.btn-green-1 {
  background-color: #fff;
  color: #FFBE00;
  border: 1px solid #FFBE00!important;
  transition: all 0.3s ease;
}
.btn-green-1:hover{
  background-color: #FFBE00;
  color: #fff;
}
.navbar-toggler {
  background: #FFBE00;
}
.flex-start-just {
  display: flex;
  justify-content: safe start;
}



/*footer tag for new*/

.footer-04 {
  background: #272727;
  padding-bottom: 0;
}

footer {
  padding: 2.5em 0;
    padding-bottom: 2em;
}

.footer-04 .footer-heading {
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.footer-04 p {
  color: rgb(255, 255, 255);
}

.footer-04 a {
  color: rgb(255, 255, 255);
}

.footer-04 .footer-heading .logo {
  letter-spacing: none;
  font-size: 16px;
  color: #fff;
  text-transform: capitalize;
}

.footer-04 .list-unstyled li a {
  color: rgba(255,255,255,.7);
} 

.rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.ftco-footer-social {
  color: rgba(255,255,255,.7)!important;
  display: flex;
  flex-direction: row;
  list-style: none;
}

.ftco-footer-social li {
  margin-right: 20px;
  font-size: 24px;
  }

.pad-top-bot {
  padding-top: 15px;
  padding-bottom: 5px;
  background: #FFBE00;
  color: #fff!important;
} 

input#Userdetails_ud_phone {
  height: 50px!important;
}
i.fa-ser-bar {
  /*right: 10%;*/
  color: #000;
}

@media only screen and (max-device-width: 320px) {
  #navbarSupportedContent {
    position:relative;
    left: 0!important;
  }

  #navbarSupportedContent {
      padding-top: 10px !important;
   }

   input::placeholder {
    font-size: 12px;
  }

  .agile_featured-profiles .span-tag  {
    bottom: 175px;
    right: 10%;
  }

  }

@media only screen and (max-device-width: 768px) {
    /* STYLES HERE */

    #navbarSupportedContent {
    float: right;
    position: relative;
    /*left: -40%;*/
    /*padding-top: 12px;*/
}


} 



/*All Device Responsive for 11-7-2022*/

@media only screen and (max-device-width: 600px) {

.mb-400 {
  margin-bottom: 25px!important;
}
.err-msg {top: 75%;}

}


/*For mobile devices: 320px to 480px*/

@media screen and (min-width:320px) and (max-width:480px) {

#home {
    background-position: -225px;
  }

.award_wins_number, .business_partner_number, .happy_clients_number, .project_completed_number {
  width: 100%!important;
  margin-bottom: 30px;
}

.main-header .header-lower .logo {
  width: 160px;
}

.navbar-toggler {
  position: absolute;
  right: 10px;
  top: 25px;
  z-index: 1000;
}

.align-cen {
  margin-bottom: 20px;
  margin-left: 15px;
}

.find-section {
  position: absolute;
  top: 25%;
}
  
.finder-caption h1 {
  font-size: 28px;
  margin-bottom: 20px;
}

.finder-caption h2, .finder-caption p {
  margin-bottom: 30px;
 }

.agile-assisted-service p {
  width: 95%;
}

.footer-04 p {
  font-size: 14px;
}

#navbarSupportedContent {
  padding-top: 85px;
}

}




/*For tablets and ipads: 481px to 768px*/

@media screen and (min-width:481px) and (max-width:768px) {

.navbar-toggler {
  position: absolute;
  right: 10px;
  top: 20px;
  z-index: 1000;
}
.err-msg-before {
  top: 225px!important;}


#navbarSupportedContent {
  padding-top: 80!important;
}

.align-cen {
  margin-bottom: 20px;
}

.find-section {
  top: 38%;
}
.find-section h1{
  font-size: 2rem;
}

.agile_featured-profiles .span-tag {
  bottom: 135px;
  left: 85%;
}

}



/*For laptops: 769px to 1024px*/

@media screen and (min-width:769px) and (max-width:1024px) {

  .main-header .header-lower .logo {
    width: 160px!important;
    line-height: 70px;
  } 

  .logo{
    margin-left: -95px!important;
  }

  .main-header .header-lower .logo img {
    max-width: 80%;
    margin-left: 70px;  
}

  .navbar-light .navbar-nav a.nav-link {
    font-size: 15px;
    padding: 0.9rem .7rem;
  }

  .find-section {
      top: 36%;
  }

}


    
/*For desktops: 1025px to 1200px*/

@media screen and (min-width:1025px) and (max-width:1200px) {
  .find-section {
      top: 40%;
  }
  .navbar-light .navbar-nav a.nav-link {
      padding: 0.7rem!important;
 }
 .logged {
  left: -140px!important;
}

} 



/*Back to Top Button*/


#scroll {
  position: fixed;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  width: 40px;
  height: 45px;
  text-indent: -9999px;
  display: none;
  -webkit-border-radius: 60px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  background: #FFBE00;
}

#scroll span {
    position:absolute;
    top:50%;
    left:50%;
    margin-left:-8px;
    margin-top:-12px;
    height:0;
    width:0;
    border:8px solid transparent;
    border-bottom-color:#ffffff;
}

#scroll:hover {
    opacity:1;filter:"alpha(opacity=100)";
    -ms-filter:"alpha(opacity=100)";
}




/*Data table css*/

.first.form-control:focus{
  border-color: #4a4a4a;
  box-shadow: none;
}
.mid.form-control:focus{
  border-color: #4a4a4a;
  box-shadow: none;
}
.last.form-control:focus{
  border-color: #4a4a4a;
  box-shadow: none;
}
.first{
  height: 50px !important;
  border-radius: 0px;
  border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 3px solid #b1b1b1;
    box-shadow: none;
    margin-right: 10px;
  padding: 3px 3px;
  font-size: 36px;
    font-weight: 600;
    text-align: center;
}
.mid{
  height: 50px !important;
  border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 3px solid #b1b1b1;
    box-shadow: none;
    margin-right: 10px;
      padding: 3px 3px;
  font-size: 36px;
    font-weight: 600;
    text-align: center;
}
.last{
   border-radius: 0px;
  height: 50px !important;
  border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-bottom: 3px solid #b1b1b1;
    box-shadow: none;
      padding: 3px 3px;
  font-size: 36px;
    font-weight: 600;
    text-align: center;
   
}
.pincode-input-container {
  display:inline-block;
}
.pincode-input-container input.first {
  border-top-right-radius:0px;
  border-bottom-right-radius:0px;
}
.pincode-input-container input.last {
  border-top-left-radius:0px;
  border-bottom-left-radius:0px;
  border-left-width:0px;
}
.pincode-input-container input.mid {
  border-radius:0px;
  border-left-width:0px;
}
.pincode-input-text, .form-control.pincode-input-text {
    width: 50px;
    float: left;
}
.pincode-input-error{
  clear:both;
}
.pincode-input-container.touch .touchwrapper{
  position:relative;
  height:34px;
  margin-right:5px;
  overflow:hidden;
}
.pincode-input-container.touch .touchwrapper .pincode-input-text{
  position: absolute;
    top: 0px;
    left: 0px;
    right:0px;
    width: 100%;
    display: block;
    background-color:transparent;
    background:transparent;
    letter-spacing:20px;
}
/*
  On every digit we use the letter-spacing of the above textbox to determine the table background
*/
.pincode-input-container.touch .touchwrapper.touch1{width:40px;}
.pincode-input-container.touch .touchwrapper.touch2{width:65px;}
.pincode-input-container.touch .touchwrapper.touch3{width:90px;}
.pincode-input-container.touch .touchwrapper.touch4{width:120px;}
.pincode-input-container.touch .touchwrapper.touch5{width:150px;}
.pincode-input-container.touch .touchwrapper.touch6{width:175px;}


.pincode-input-container.touch .touchwrapper .touchtable{
  width: 100%;
    height: 100%;
    table-layout:fixed;
}
.pincode-input-container.touch .touchwrapper .touchtable td{
  border-right:1px solid #ccc;
}
.pincode-input-container.touch .touchwrapper .touchtable td.last{
  border-right:0;
}


/* 
 *  Core Owl Carousel CSS File
 *  v1.3.3
 */

/* clearfix */

.owl-carousel .owl-wrapper:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
/* display none until init */
.owl-carousel{
  display: none;
  position: relative;
  width: 100%;
  -ms-touch-action: pan-y;
}
.owl-carousel .owl-wrapper{
  display: none;
  position: relative;
  -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-wrapper-outer{
  overflow: hidden;
  position: relative;
  width: 100%;
}
.owl-carousel .owl-wrapper-outer.autoHeight{
  -webkit-transition: height 500ms ease-in-out;
  -moz-transition: height 500ms ease-in-out;
  -ms-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
} 
.owl-carousel .owl-item{
  float: left;
}
.owl-controls .owl-page,
.owl-controls .owl-buttons div{
  cursor: pointer;
}
.owl-controls {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* mouse grab icon */
.grabbing { 
    /*cursor:url(grabbing.png) 8 8, move;*/
}

/* fix */
.owl-carousel  .owl-wrapper,
.owl-carousel  .owl-item{
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility:    hidden;
  -ms-backface-visibility:     hidden;
  -webkit-transform: translate3d(0,0,0);
  -moz-transform: translate3d(0,0,0);
  -ms-transform: translate3d(0,0,0);
}


/**
 * okaidia theme for JavaScript, CSS and HTML
 * Loosely based on Monokai textmate theme by http://www.monokai.nl/
 * @author ocodia
 */

code[class*="language-"],
pre[class*="language-"] {
  color: #f8f8f2;
  text-shadow: 0 1px rgba(0,0,0,0.3);
  font-family: Consolas, Monaco, 'Andale Mono', monospace;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

/* Code blocks */
pre[class*="language-"] {
  padding: 1em;
  margin: .5em 0;
  overflow: auto; 
  border-radius: 0.3em;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #272822;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
  padding: .1em;
  border-radius: .3em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}

.token.punctuation {
  color: #f8f8f2;
}

.namespace {
  opacity: .7;
}

.token.property,
.token.tag {
  color: #f92672;
}

.token.boolean,
.token.number{
  color: #ae81ff;
}

.token.selector,
.token.attr-name,
.token.string {
  color: #a6e22e;
}


.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #f8f8f2;
}

.token.atrule,
.token.attr-value
{
  color: #e6db74;
}


.token.keyword{
color: #66d9ef;
}

.token.regex,
.token.important {
  color: #fd971f;
}

.token.important {
  font-weight: bold;
}

.token.entity {
  cursor: help;
}
.navbar-expand-lg{
  background-color: #ffffff;
  z-index: 2;
  border-bottom: 1px solid #eee;
}
.mb-2{
  margin-bottom: 10px!important;
}
.mar{
  margin: 50px auto 0 ;
}
.login-error-msg {
    position: absolute;
    top: 120px;
 }
 .item ul li{
  list-style-type: none!important;
 }
 .mlbt-2{
  margin: 10px!important;
}
.form-control{
  /*margin-bottom: 20px!important;*/
}
.close.icon-alig-cor {
  font-size: 40px;
}
.card-img-top img.img-fluid {
  width: 100% !important;
  height: auto !important;
}
.bg-white{
  background-color: #fff;
}
.fa_mob {
   font-size: 1.3125rem;
   position: absolute;
   right: 25px;
   top: 10px;
}
.fa_eml{
   font-size: 1.3125rem;
   position: absolute;
   left: 80%;
   top: 30%;
}
/*.navbar-light .navbar-nav a.nav-link {
  color:#fff!important;
}
.main-header .header-lower, .navbar-expand-lg{
  background: #FFBE00;
}
*/
.gtMainDetHeader p b{
  color: #FFBE00!important;
}
.bg-site,.gtHSetBtn{
  background-color: #FFBE00!important;
  color: #fff!important;
}
 .gtHSetBtn:hover{
  background-color: #FFBE00!important;
  color: #fff!important;
 }
.logged {
  position: absolute;
  top: 140%;
  left: -110px;
}
.navbar-light .navbar-nav .show > .nav-link, 
.navbar-light .navbar-nav .active > .nav-link, 
.navbar-light .navbar-nav .nav-link.show, .navbar-light 
.navbar-nav .nav-link.active{
    color: #fff!important;
}
/*.fa_alt{
  top:10%!important;
  left:90%!important;
}*/
#rasi_box12{
  background: url('/src/assets/images/numbers/12.jpg')  no-repeat center;
}
#rasi_box1{
  background: url('/src/assets/images/numbers/1.jpg')  no-repeat center;
}
#rasi_box2{
  background: url('/src/assets/images/numbers/2.jpg')  no-repeat center;
} 
#rasi_box3{
  background: url('/src/assets/images/numbers/3.jpg')  no-repeat center;
} 
#rasi_box4{
  background: url('/src/assets/images/numbers/4.jpg')  no-repeat center;
} 
#rasi_box5{
  background: url('/src/assets/images/numbers/5.jpg')  no-repeat center;
} 
#rasi_box6{
  background: url('/src/assets/images/numbers/6.jpg')  no-repeat center;
} 
#rasi_box7{
  background: url('/src/assets/images/numbers/7.jpg')  no-repeat center;
} 
#rasi_box8{
  background: url('/src/assets/images/numbers/8.jpg')  no-repeat center;
} 
#rasi_box9{
  background: url('/src/assets/images/numbers/9.jpg')  no-repeat center;
} 
#rasi_box10{
  background: url('/src/assets/images/numbers/10.jpg')  no-repeat center;
} 
#rasi_box11{
  background: url('/src/assets/images/numbers/11.jpg')  no-repeat center;
} 
.owl-dots{
  display: none;
}
.owl-nav{
  background-color: #FFBE00!important;
  color: #fff!important;
  width: 70px;
  align-items: center;
  border-radius: 100px;
  font-size: 40px;
  margin: 0 100px;
}
.owl-theme .owl-nav{
  max-height: 35px;
}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot{
  position: relative;
  top: -20px;

}
.owl-theme .owl-nav [class*="owl-"]:hover{
  background: none!important;
}
.navbar-light .navbar-nav a.nav-link {
  padding: 0.9rem;
}
.gtProBox .gtProBoxHead .btn-green:hover, .gtProBox .gtProBoxHead .btn-green:focus {
  transition: all 0.3s ease;
  background: #FFBE00;
  color: #FFBE00;
}
.card {
  /*margin:10px!important;*/
  max-width:100%!important;
}
.img-fluid {
  /*min-width: 200px;*/
}
.photo .img-fluid {
  /* height: 210px !important; */
}

.gt-msg-board .gt-msg-top-strip {
    background: #F9F9F9;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    padding-top: 10px;  
    padding-bottom: 10px;
}
.gt-margin-top-10 {
    margin-top: 10px;
}
.col-xxl-16 {
    width: 100%;
}
.col-xl-16, .col-xxl-16 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
element.style {
    min-height: 200px;
    width: 100%;
}
.gt-form-control {
    display: block;
    width: 100%;
    min-height: 40px;
    padding: 9px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}
.gt-margin-top-15 {
    margin-top: 15px;
}
input, button, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}
.gt-msg-board .gt-msg-top-strip {
    background: #F9F9F9;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}
#home > .error{
  position: absolute;
  color: #fff;
  text-align: center;
  margin-top: 40px !important;
  padding-left: 16px;
  margin-bottom: 0;
}

.error{
  color: red!important;
  position: absolute;
  left: 15px;
  font-size: 14px;
}


/*.input-group-prepend{
  margin-bottom: 20px;
}*/
.react-loading-skeleton{
  line-height: 24px!important;
}
.error-msg{
  color: red !important;
}
/*.fa.fa_eyeopen {
  top: 10px !important;
}*/
.theme-color{
  color: #ff4500!important;
}
.page-link:hover{
  background-color: #FFBE00!important;
  color: #fff!important;
}

/* -------- 23-09-2022 _------ */
.page_404{ 
  padding:40px 0;
  background:#eee;
  font-family: 'Arvo', serif;
}
.page_404  img{ 
  width: 23%;
  background: #fff;
  padding: 30px;
  border-radius: 50%;
}
.four_zero_four_bg{
  background-image: url('/src/assets/images/404.png');
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
}
.gif-404 {
  position: absolute;
  top: 75px;
  left: 380px;
}
.four_zero_four_bg h1{
  font-size:80px;
}
.four_zero_four_bg h3{
  font-size:80px;
}  
.link_404{      
  color: #fff!important;
  padding: 10px 20px;
  background: #ff4500;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_404{ 
  margin-top:-50px;
}
@media (min-width: 769px) and (max-width: 1024px) and (orientation: landscape) {
  .page_404 img {
    width: 30%;
  }
  .gif-404 {
    position: absolute;
    top: 65px;
    left: 270px;
  }
}
@media (min-width: 481px) and (max-width: 768px) {
  .page_404 img {
    width: 40%;
  }
  .gif-404 {
    position: absolute;
    top: 75px;
    left: 175px;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .four_zero_four_bg { 
       background-size: 100%;
  }
  .page_404 img {
    width: 33%;
    background: #fff;
    padding: 20px;
    border-radius: 50%;
  }
  .gif-404 {
    position: absolute;
    top: 120px;
    left: 145px;
  }
  .pll-480 {
     padding-left: 20px;
     padding-top: 10px;
  }
  .disply-no {
    display: none;
  }
}
@media (max-width: 320px) {

  .gtProBox .gtProBoxHead .btn-green {
    padding: 0.4rem .8rem;
  }

  .page_404 img {
    width: 33%;
    background: #fff;
    padding: 20px;
    border-radius: 50%;
  }
  .gif-404 {
    position: absolute;
    top: 145px;
    left: 95px;
  }
} 

@media only screen and (max-width: 991px) {

  .align-cen {
    margin-left: 15px;
  }

  #Userdetails_ud_phone::placeholder {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: "-";
  }
  #navbarSupportedContent {
    padding-bottom: 85px;
}
.navbar-nav .dropdown-menu {
  position: absolute;
  float: none;
  left: 0;
  top: 50px;
}

 .bg-site {
  margin-left: 10px;
 }

}


/* -------- 23-09-2022 _------ */
.search_201{ 
  padding:40px 0;
  background:#fff;
  font-family: 'Arvo', serif;
}
.search_not_found{
  background-image: url('/src/assets/images/ser.png'); 
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
 }
.search_201 img {
  width: 40%;
}
.search_not {
  position: absolute;
  top: -2%;
  left: 27%;
}
.gtMainResult >.search_not {
  position: absolute;
top: 10%!important;
left: 25%!important;
  }
.search_not_found h1{
 font-size:80px;
}
.search_not_found h3{
  font-size:80px;
}
.link_404{      
  color: #fff!important;
  padding: 10px 20px;
  background: #ff4500;
  margin: 20px 0;
  display: inline-block;
}
.contant_box_search{ 
  margin-top:-50px;
}
@media (min-width: 769px) and (max-width: 1024px) and (orientation: landscape) {
  .search_201 img {
    width: 40%;
  }
  .search_not {
  position: absolute;
  top: 5%;
  left: 26%!important;
  height: auto !important;
}
}
@media (min-width: 481px) and (max-width: 768px) {
  .search_201 img {
    width: 60%;
  }
  .search_not {
    position: absolute;
  top: 10px;
  left: 14%;
  }
}
@media (min-width: 320px) and (max-width: 480px) {
  .search_201 img {
    width: 75%;
  }
  .search_not {
    position: absolute;
  top: 45px;
  left: 3%;
  }
}
@media (max-width: 320px) {
  .search_201 img {
    width: 100%;
  }
  .search_not {
    position: absolute;
    top: 11%;
   left: -38px;
  }
}


[aria-busy='true'],[aria-live="polite"] {
  height: 10px;
}


/*18-10-2022 style for responsive*/


@media (min-width: 1300px) and (max-width: 2000px) {
  .find-section {
  position: absolute;
  top: 40%;
}
.gtRegMain p{
  padding-left: 60px;
}

.gtRegMain label{
  /*padding-left: 66px;*/
}
}

div .navbar {
  margin-bottom: 80px;
}


#navbarSupportedContent {
  padding-top: 20px;
}


/*Date for 20-10-2022*/

/*#eye-cl {
  top: 87px;
}*/

.alig-top {
  top: 87px!important;
}

.react-datepicker__navigation-icon {
  position: relative;
  top: 5px!important;
}

label .custom-file-label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: "-----";
}

#eye-cl {
  top: 87px;
}

/*.mt-4 {
  margin-top: 120px!important;
}*/
a {
  color: #ff4500;
}

.gtMainResult { box-shadow: none};


#mr-top {
  margin-top: 6rem!important;
}


.pincode-input-container > .error {
  left: 42%;
  top: 50%;
}

.mt-5, .my-5 {
  margin-top: 8rem !important;
}

footer a:hover {
  color: #fff!important;
}
.react-datepicker__triangle {
  transform: translate(45px)!important;
}

.gtMainResult button{
  text-align: left!important;
}

.gtRegMain p .error {
  padding-left: 0!important;}

.gt-msg-top-strip > p .error {
  padding-left: 20px!important;
}

.gt-msg-top-strip p.error {
  position: relative;
  line-height: 0px;
  top: 10px;
  left: 0;
} 

#left-0{
  left: 0!important;
}

#advancedsearch-form .form-group {
  margin-bottom: 10px!important;
}

.gtRegMain p.error {padding-left: 0;
}

.forget-msg p.error {
    position: relative;
  line-height: 0px;
  top: -5px;
  left: 0;
}

.err-msg-before {
  top: 171px;
  left: 35px;
}

 .gtMsgRes button:hover,  .gtMsgRes button:focus {
  background-color: #FFBE00;
  color: white!important;
  transition: all 0.2s ease;
}

.btn a:hover,  .btn a:focus {color: #fff!important;}  

.gtMainResult .gtResTag {
  font-size: 1rem;}
  .gtMainResult .gtResDet {
  font-size: 1rem;
  text-align: left;
}

@media (min-width: 480px) and (max-width: 575px) {
.owl-item{
  width: 400px!important;
}
.owl-nav{margin: 0 170px;}
}
@media (min-width: 768px) and (max-width: 1024px) {
  .owl-item{
  width: 425px!important;
  }
  .owl-nav{margin: 0 180px;}
}
.lang-ta{
  text-align: center!important;
}

.modalBodyHeight{
  height: 75vh;
  overflow-y: auto!important;
}
 .modal-dialog {
  margin: 20px auto!important;
 }
 .modal {
  padding-top: 50px!important;
 }
 .react-datepicker-wrapper {
  width: 100%;
 }
 .modal {
  z-index: 2;
 }